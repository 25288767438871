export function fetchDomain(payload) {
  return {type: 'FETCH_DOMAIN', payload}
}

export function fetchDomainDone(payload) {
  return {type: 'FETCH_DOMAIN_DONE', payload}
}

export function fetchDomainsBreakdown(payload) {
  return {type: 'FETCH_DOMAINS_BREAKDOWN', payload}
}

export function fetchDomainsBreakdownDone(payload) {
  return {type: 'FETCH_DOMAINS_BREAKDOWN_DONE', payload}
}

export function fetchDomainRecords(payload) {
  return {type: 'FETCH_DOMAIN_RECORDS', payload}
}

export function fetchDomainRecordsDone(payload) {
  return {type: 'FETCH_DOMAIN_RECORDS_DONE', payload}
}

export function fetchDnsRecordsLogs(payload) {
  return {type: 'FETCH_DNS_RECORDS_LOGS', payload}
}

export function fetchDnsRecordsLogsDone(payload) {
  return {type: 'FETCH_DNS_RECORDS_LOGS_DONE', payload}
}

export function fetchDomainSubdomains(payload) {
  return {type: 'FETCH_DOMAIN_SUBDOMAINS', payload}
}

export function fetchDomainSubdomainsDone(payload) {
  return {type: 'FETCH_DOMAIN_SUBDOMAINS_DONE', payload}
}

export function fetchDomainFindings(payload) {
  return {type: 'FETCH_DOMAIN_FINDINGS', payload}
}

export function fetchDomainFindingsDone(payload) {
  return {type: 'FETCH_DOMAIN_FINDINGS_DONE', payload}
}

export function fetchDomains(payload) {
  return {type: 'FETCH_DOMAINS', payload}
}

export function fetchDomainsDone(payload) {
  return {type: 'FETCH_DOMAINS_DONE', payload}
}

export function fetchDomainsHostnames(payload) {
  return {type: 'FETCH_DOMAINS_HOSTNAMES', payload}
}

export function fetchDomainsHostnamesDone(payload) {
  return {type: 'FETCH_DOMAINS_HOSTNAMES_DONE', payload}
}

export function fetchDomainsByRegistrar(payload) {
  return {type: 'FETCH_DOMAINS_BY_REGISTRAR', payload}
}

export function fetchDomainsByRegistrarDone(payload) {
  return {type: 'FETCH_DOMAINS_BY_REGISTRAR_DONE', payload}
}

export function fetchDnsStats(payload) {
  return {type: 'FETCH_DNS_STATS', payload}
}

export function fetchDnsStatsDone(payload) {
  return {type: 'FETCH_DNS_STATS_DONE', payload}
}

export function clearNameServerDetails(payload) {
  return {type: 'CLEAR_NAME_SERVER_DETAILS', payload}
}

export function fetchNameServerDetails(payload) {
  return {type: 'FETCH_NAME_SERVER_DETAILS', payload}
}

export function fetchNameServerDetailsDone(payload) {
  return {type: 'FETCH_NAME_SERVER_DETAILS_DONE', payload}
}

export function fetchNameServers(payload) {
  return {type: 'FETCH_NAME_SERVERS', payload}
}

export function fetchNameServersDone(payload) {
  return {type: 'FETCH_NAME_SERVERS_DONE', payload}
}

export function updateWebFinding(payload) {
  return {type: 'UPDATE_WEB_FINDING', payload}
}

export function updateWebFindingDone(payload) {
  return {type: 'UPDATE_WEB_FINDING_DONE', payload}
}

export function fetchWebFinding(payload) {
  return {type: 'FETCH_WEB_FINDING', payload}
}

export function fetchWebFindingDone(payload) {
  return {type: 'FETCH_WEB_FINDING_DONE', payload}
}

export function addWebApp(payload) {
  return {type: 'ADD_WEB_APP', payload}
}

export function addWebAppDone(payload) {
  return {type: 'ADD_WEB_APP_DONE', payload}
}

export function fetchWebFindingActivity(payload) {
  return {type: 'FETCH_WEB_FINDING_ACTIVITY', payload}
}

export function fetchWebFindingActivityDone(payload) {
  return {type: 'FETCH_WEB_FINDING_ACTIVITY_DONE', payload}
}

export function fetchWebStats(payload) {
  return {type: 'FETCH_WEB_STATS', payload}
}

export function fetchWebStatsDone(payload) {
  return {type: 'FETCH_WEB_STATS_DONE', payload}
}

export function fetchWebImportantFindings(payload) {
  return {type: 'FETCH_WEB_IMPORTANT_FINDINGS', payload}
}

export function fetchWebImportantFindingsDone(payload) {
  return {type: 'FETCH_WEB_IMPORTANT_FINDINGS_DONE', payload}
}

export function clearFingerprintDetails(payload) {
  return {type: 'CLEAR_FINGERPRINT_DETAILS', payload}
}

export function fetchFingerprintDetails(payload) {
  return {type: 'FETCH_FINGERPRINT_DETAILS', payload}
}

export function fetchFingerprintDetailsDone(payload) {
  return {type: 'FETCH_FINGERPRINT_DETAILS_DONE', payload}
}

export function fetchWebAppsByProtocol(payload) {
  return {type: 'FETCH_WEB_APPS_BY_PROTOCOL', payload}
}

export function fetchWebAppsByProtocolDone(payload) {
  return {type: 'FETCH_WEB_APPS_BY_PROTOCOL_DONE', payload}
}

export function fetchWebAppsFingerprints(payload) {
  return {type: 'FETCH_WEB_APPS_FINGERPRINTS', payload}
}

export function fetchWebAppsFingerprintsDone(payload) {
  return {type: 'FETCH_WEB_APPS_FINGERPRINTS_DONE', payload}
}

export function fetchCompanyMembers(payload) {
  return {type: 'FETCH_COMPANY_MEMBERS', payload}
}

export function fetchCompanyMembersDone(payload) {
  return {type: 'FETCH_COMPANY_MEMBERS_DONE', payload}
}

export function fetchCompaniesPagination(payload) {
  return {type: 'FETCH_COMPANIES_PAGINATION', payload}
}

export function fetchCompaniesDonePagination(payload) {
  return {type: 'FETCH_COMPANIES_DONE_PAGINATION', payload}
}

export function fetchCompanyScores(payload) {
  return {type: 'FETCH_COMPANY_SCORES', payload}
}

export function fetchCompanyScoresDone(payload) {
  return {type: 'FETCH_COMPANY_SCORES_DONE', payload}
}

export function fetchCompanyStats(payload) {
  return {type: 'FETCH_COMPANY_STATS', payload}
}

export function fetchCompanyStatsDone(payload) {
  return {type: 'FETCH_COMPANY_STATS_DONE', payload}
}

export function selectCompany(payload) {
  return {type: 'SELECT_COMPANY', payload}
}

export function acceptInvite(payload) {
  return {type: 'ACCEPT_INVITE', payload}
}

export function rejectInvite(payload) {
  return {type: 'REJECT_INVITE', payload}
}

export function fetchInvites(payload) {
  return {type: 'FETCH_INVITES', payload}
}

export function fetchInvitesDone(payload) {
  return {type: 'FETCH_INVITES_DONE', payload}
}

export function fetchInviteInfo(payload) {
  return {type: 'FETCH_INVITE_INFO', payload}
}

export function fetchInviteInfoDone(payload) {
  return {type: 'FETCH_INVITE_INFO_DONE', payload}
}

export function acceptTerms(payload) {
  return {type: 'ACCEPT_TERMS', payload}
}

export function acceptTermsDone(payload) {
  return {type: 'ACCEPT_TERMS_DONE', payload}
}

export function updateCompany(payload) {
  return {type: 'UPDATE_COMPANY', payload}
}

export function inviteMember(payload) {
  return {type: 'INVITE_MEMBER', payload}
}

export function cancelInvite(payload) {
  return {type: 'CANCEL_INVITE', payload}
}

export function deleteMember(payload) {
  return {type: 'DELETE_MEMBER', payload}
}

export function loading(payload) {
  return {type: 'LOADING', payload}
}

export function removeMember(payload) {
  return {type: 'REMOVE_MEMBER', payload}
}

export function selectDomain(payload) {
  return {type: 'SELECT_DOMAIN', payload}
}

export function removeDomain(payload) {
  return {type: 'DEL_DOMAIN', payload}
}

export function fetchHost(payload) {
  return {type: 'FETCH_HOST', payload}
}

export function fetchHostDone(payload) {
  return {type: 'FETCH_HOST_DONE', payload}
}

export function fetchHostFindings(payload) {
  return {type: 'FETCH_HOST_FINDINGS', payload}
}

export function fetchHostFindingsDone(payload) {
  return {type: 'FETCH_HOST_FINDINGS_DONE', payload}
}

export function fetchHosts(payload) {
  return {type: 'FETCH_HOSTS', payload}
}

export function fetchHostsDone(payload) {
  return {type: 'FETCH_HOSTS_DONE', payload}
}

export function fetchHostsError(payload) {
  return {type: 'FETCH_HOSTS_ERROR', payload}
}

export function fetchHostsByAsOrgs(payload) {
  return {type: 'FETCH_HOSTS_BY_AS_ORGS', payload}
}

export function fetchHostsByAsOrgsDone(payload) {
  return {type: 'FETCH_HOSTS_BY_AS_ORGS_DONE', payload}
}

export function fetchHostsByCountry(payload) {
  return {type: 'FETCH_HOSTS_BY_COUNTRY', payload}
}

export function fetchHostsByCountryDone(payload) {
  return {type: 'FETCH_HOSTS_BY_COUNTRY_DONE', payload}
}

export function selectHost(payload) {
  return {type: 'SELECT_HOST', payload}
}

export function removeHost(payload) {
  return {type: 'DEL_HOST', payload}
}

export function updateTags(payload) {
  return {type: 'UPDATE_TAGS', payload}
}

export function updateDescription(payload) {
  return {type: 'UPDATE_DESCRIPTION', payload}
}

export function delCompany(payload) {
  return {type: 'DEL_COMPANY', payload}
}

export function addCompany(payload) {
  return {type: 'ADD_COMPANY', payload}
}

export function genericError(payload) {
  return {type: 'GENERIC_ERROR', payload}
}

export function redirectFeedback(payload) {
  return {type: 'REDIRECT_FEEDBACK', payload}
}

export function success(payload) {
  return {type: 'SUCCESS', payload}
}

export function clearSuccess(payload) {
  return {type: 'CLEAR_SUCCESS', payload}
}

export function clearError(payload) {
  return {type: 'CLEAR_ERROR', payload}
}

export function clearFilters(payload) {
  return {type: 'CLEAR_FILTERS', payload}
}

export function clearAction(payload) {
  return {type: 'CLEAR_ACTION', payload}
}

export function clearCloudIntegrationFilters(payload) {
  return {type: 'CLEAR_CLOUD_INTEGRATION_FILTERS', payload}
}

export function authenticate(payload) {
  return {type: 'AUTHENTICATE', payload}
}

export function authenticateDone(payload) {
  return {type: 'AUTHENTICATE_DONE', payload}
}

export function signUp(payload) {
  return {type: 'SIGN_UP', payload}
}

export function fetchUser() {
  return {type: 'FETCH_USER'}
}

export function fetchUserDone(payload) {
  return {type: 'FETCH_USER_DONE', payload}
}

export function logout(payload) {
  return {type: 'LOGOUT', payload}
}

export function logoutDone() {
  return {type: 'LOGOUT_DONE'}
}

export function register(payload) {
  return {type: 'REGISTER', payload}
}

export function registerDone(payload) {
  return {type: 'REGISTER_DONE', payload}
}

export function forgotPassword(payload) {
  return {type: 'FORGOT_PASSWORD', payload}
}

export function forgotPasswordDone(payload) {
  return {type: 'FORGOT_PASSWORD_DONE', payload}
}

export function resetPassword(payload) {
  return {type: 'RESET_PASSWORD', payload}
}

export function resetPasswordDone(payload) {
  return {type: 'RESET_PASSWORD_DONE', payload}
}

export function showPlanRenewalModal(payload) {
  return {type: 'SHOW_PLAN_RENEWAL_MODAL', payload}
}

export function toggleRestrictionModal(payload) {
  return {type: 'TOGGLE_RESTRICTION_MODAL', payload}
}

export function showAutoSubscriptionModal(payload) {
  return {type: 'SHOW_AUTO_SUBSCRIPTION_MODAL', payload}
}

export function memberGetMemberModal(payload) {
  return {type: 'MEMBER_GET_MEMBER_MODAL', payload}
}

export function fetchDNS(payload) {
  return {type: 'FETCH_DNS', payload}
}

export function fetchDNSDone(payload) {
  return {type: 'FETCH_DNS_DONE', payload}
}

export function updateIssueStatus(payload) {
  return {type: 'UPDATE_ISSUE_STATUS', payload}
}

export function fetchIssuesWeb(payload) {
  return {type: 'FETCH_ISSUES_WEB', payload}
}

export function fetchIssuesWebDone(payload) {
  return {type: 'FETCH_ISSUES_WEB_DONE', payload}
}

export function fetchIssuesDns(payload) {
  return {type: 'FETCH_ISSUES_DNS', payload}
}

export function fetchIssuesDnsDone(payload) {
  return {type: 'FETCH_ISSUES_DNS_DONE', payload}
}

export function saveProfile(payload) {
  return {type: 'SAVE_PROFILE', payload}
}

export function passwordChanged() {
  return {type: 'PASSWORD_CHANGED'}
}

export function currentPasswordWrong() {
  return {type: 'CURRENT_PASSWORD_WRONG'}
}

export function updatePassword(payload) {
  return {type: 'UPDATE_PASSWORD', payload}
}

export function toggleCompanyMonitoring(payload) {
  return {type: 'TOGGLE_COMPANY_MONITORING', payload}
}

export function fetchFeed(payload) {
  return {type: 'FETCH_FEED', payload}
}

export function fetchFeedDone(payload) {
  return {type: 'FETCH_FEED_DONE', payload}
}

export function disconnectGoogle(payload) {
  return {type: 'DISCONNECT_GOOGLE', payload}
}

export function disconnectMicrosoft(payload) {
  return {type: 'DISCONNECT_MICROSOFT', payload}
}

export function fetchCompany(payload) {
  return {type: 'FETCH_COMPANY', payload}
}

export function fetchCompanyDone(payload) {
  return {type: 'FETCH_COMPANY_DONE', payload}
}

export function companyRenewal(payload) {
  return {type: 'COMPANY_RENEWAL', payload}
}

export function companyRenewalDone(payload) {
  return {type: 'COMPANY_RENEWAL_DONE', payload}
}

export function fetchBreaches(payload) {
  return {type: 'FETCH_BREACHES', payload}
}

export function fetchBreachesDone(payload) {
  return {type: 'FETCH_BREACHES_DONE', payload}
}

export function fetchPeopleAccount(payload) {
  return {type: 'FETCH_PEOPLE_ACCOUNT', payload}
}

export function fetchPeopleAccountDone(payload) {
  return {type: 'FETCH_PEOPLE_ACCOUNT_DONE', payload}
}

export function fetchPeopleAccountFindings(payload) {
  return {type: 'FETCH_PEOPLE_ACCOUNT_FINDINGS', payload}
}

export function fetchPeopleAccountFindingsDone(payload) {
  return {type: 'FETCH_PEOPLE_ACCOUNT_FINDINGS_DONE', payload}
}

export function fetchPeopleFinding(payload) {
  return {type: 'FETCH_PEOPLE_FINDING', payload}
}

export function fetchPeopleFindingDone(payload) {
  return {type: 'FETCH_PEOPLE_FINDING_DONE', payload}
}

export function updatePeopleFindingStatus(payload) {
  return {type: 'UPDATE_PEOPLE_FINDING_STATUS', payload}
}

export function updatePeopleFindingStatusDone(payload) {
  return {type: 'UPDATE_PEOPLE_FINDING_STATUS_DONE', payload}
}

export function fetchPeopleFindingActivity(payload) {
  return {type: 'FETCH_PEOPLE_FINDING_ACTIVITY', payload}
}

export function fetchPeopleFindingActivityDone(payload) {
  return {type: 'FETCH_PEOPLE_FINDING_ACTIVITY_DONE', payload}
}

export function updateFindingAssignee(payload) {
  return {type: 'UPDATE_FINDING_ASSIGNEE', payload}
}

export function fetchIssues(payload) {
  return {type: 'FETCH_ISSUES', payload}
}

export function fetchIssuesDone(payload) {
  return {type: 'FETCH_ISSUES_DONE', payload}
}

export function fetchService(payload) {
  return {type: 'FETCH_SERVICE', payload}
}

export function fetchServiceDone(payload) {
  return {type: 'FETCH_SERVICE_DONE', payload}
}

export function fetchServiceDetails(payload) {
  return {type: 'FETCH_SERVICE_DETAILS', payload}
}

export function fetchServiceDetailsDone(payload) {
  return {type: 'FETCH_SERVICE_DETAILS_DONE', payload}
}

export function fetchServiceDetailsFindings(payload) {
  return {type: 'FETCH_SERVICE_DETAILS_FINDINGS', payload}
}

export function fetchServiceDetailsFindingsDone(payload) {
  return {type: 'FETCH_SERVICE_DETAILS_FINDINGS_DONE', payload}
}

export function fetchHostsByNetworkService(payload) {
  return {type: 'FETCH_HOSTS_BY_NETWORK_SERVICE', payload}
}

export function fetchHostsByNetworkServiceDone(payload) {
  return {type: 'FETCH_HOSTS_BY_NETWORK_SERVICE_DONE', payload}
}

export function fetchServices(payload) {
  return {type: 'FETCH_SERVICES', payload}
}

export function fetchServicesDone(payload) {
  return {type: 'FETCH_SERVICES_DONE', payload}
}

export function selectService(payload) {
  return {type: 'SELECT_SERVICE', payload}
}

export function fetchTotalVsClosedIssues(payload) {
  return {type: 'FETCH_TOTAL_VS_CLOSED_ISSUES', payload}
}

export function fetchTotalVsClosedIssuesDone(payload) {
  return {type: 'FETCH_TOTAL_VS_CLOSED_ISSUES_DONE', payload}
}

export function fetchBreachedAccounts(payload) {
  return {type: 'FETCH_BREACHED_ACCOUNTS', payload}
}

export function fetchBreachedAccountsDone(payload) {
  return {type: 'FETCH_BREACHED_ACCOUNTS_DONE', payload}
}

export function saveFilter(payload) {
  return {type: 'SAVE_FILTER', payload}
}

export function completeGuide(payload) {
  return {type: 'COMPLETE_GUIDE', payload}
}

export function addDomain(payload) {
  return {type: 'ADD_DOMAIN', payload}
}

export function submitSurvey(payload) {
  return {type: 'SUBMIT_SURVEY', payload}
}

export function fetchCloudtrailTrails(payload) {
  return {type: 'FETCH_CLOUDTRAIL_TRAILS', payload}
}

export function fetchCloudtrailTrailsDone(payload) {
  return {type: 'FETCH_CLOUDTRAIL_TRAILS_DONE', payload}
}

export function fetchRdsInstances(payload) {
  return {type: 'FETCH_RDS_INSTANCES', payload}
}

export function fetchRdsInstancesDone(payload) {
  return {type: 'FETCH_RDS_INSTANCES_DONE', payload}
}

export function fetchRdsSnapshots(payload) {
  return {type: 'FETCH_RDS_SNAPSHOTS', payload}
}

export function fetchRdsSnapshotsDone(payload) {
  return {type: 'FETCH_RDS_SNAPSHOTS_DONE', payload}
}

export function fetchKmsKeys(payload) {
  return {type: 'FETCH_KMS_KEYS', payload}
}

export function fetchKmsKeysDone(payload) {
  return {type: 'FETCH_KMS_KEYS_DONE', payload}
}

export function generateApiKey(payload) {
  return {type: 'GENERATE_API_KEY', payload}
}

export function generateApiKeyDone(payload) {
  return {type: 'GENERATE_API_KEY_DONE', payload}
}

export function removeApiKey(payload) {
  return {type: 'REMOVE_API_KEY', payload}
}

export function fetchApiKeys(payload) {
  return {type: 'FETCH_API_KEYS', payload}
}

export function fetchApiKeysDone(payload) {
  return {type: 'FETCH_API_KEYS_DONE', payload}
}

export function fetchEc2Amis(payload) {
  return {type: 'FETCH_EC2_AMIS', payload}
}

export function fetchEc2AmisDone(payload) {
  return {type: 'FETCH_EC2_AMIS_DONE', payload}
}

export function fetchEc2Instances(payload) {
  return {type: 'FETCH_EC2_INSTANCES', payload}
}

export function fetchEc2InstancesDone(payload) {
  return {type: 'FETCH_EC2_INSTANCES_DONE', payload}
}

export function fetchEc2SecurityGroups(payload) {
  return {type: 'FETCH_EC2_SECURITY_GROUPS', payload}
}

export function fetchEc2SecurityGroupsDone(payload) {
  return {type: 'FETCH_EC2_SECURITY_GROUPS_DONE', payload}
}

export function fetchEc2Vpcs(payload) {
  return {type: 'FETCH_EC2_VPCS', payload}
}

export function fetchEc2VpcsDone(payload) {
  return {type: 'FETCH_EC2_VPCS_DONE', payload}
}

export function fetchEbsVolumes(payload) {
  return {type: 'FETCH_EBS_VOLUMES', payload}
}

export function fetchEbsVolumesDone(payload) {
  return {type: 'FETCH_EBS_VOLUMES_DONE', payload}
}

export function fetchEfs(payload) {
  return {type: 'FETCH_EFS', payload}
}

export function fetchEfsDone(payload) {
  return {type: 'FETCH_EFS_DONE', payload}
}

export function fetchS3Buckets(payload) {
  return {type: 'FETCH_S3_BUCKETS', payload}
}

export function fetchS3BucketsDone(payload) {
  return {type: 'FETCH_S3_BUCKETS_DONE', payload}
}

export function fetchRoute53Domains(payload) {
  return {type: 'FETCH_ROUTE_53_DOMAINS', payload}
}

export function fetchRoute53DomainsDone(payload) {
  return {type: 'FETCH_ROUTE_53_DOMAINS_DONE', payload}
}

export function fetchRoute53HostedZones(payload) {
  return {type: 'FETCH_ROUTE_53_HOSTED_ZONES', payload}
}

export function fetchRoute53HostedZonesDone(payload) {
  return {type: 'FETCH_ROUTE_53_HOSTED_ZONES_DONE', payload}
}

export function changeCurrentCompany(payload) {
  return {type: 'CHANGE_CURRENT_COMPANY', payload}
}

export function generate2fa(payload) {
  return {type: 'GENERATE_2FA', payload}
}

export function generate2faDone(payload) {
  return {type: 'GENERATE_2FA_DONE', payload}
}

export function generateReport(payload) {
  return {type: 'GENERATE_REPORT', payload}
}

export function generateReportDone(payload) {
  return {type: 'GENERATE_REPORT_DONE', payload}
}

export function enable2fa(payload) {
  return {type: 'ENABLE_2FA', payload}
}

export function disable2fa(payload) {
  return {type: 'DISABLE_2FA', payload}
}

export function check2fa(payload) {
  return {type: 'CHECK_2FA', payload}
}

export function clearQrCode() {
  return {type: 'CLEAR_QR_CODE'}
}

export function fetchWebApp(payload) {
  return {type: 'FETCH_WEB_APP', payload}
}

export function fetchWebAppDone(payload) {
  return {type: 'FETCH_WEB_APP_DONE', payload}
}

export function fetchWebApps(payload) {
  return {type: 'FETCH_WEB_APPS', payload}
}

export function fetchWebAppsDone(payload) {
  return {type: 'FETCH_WEB_APPS_DONE', payload}
}

export function fetchWebAppsFindings(payload) {
  return {type: 'FETCH_WEB_APP_FINDINGS', payload}
}

export function fetchWebAppsFindingsDone(payload) {
  return {type: 'FETCH_WEB_APP_FINDINGS_DONE', payload}
}

export function mergeWebApps(payload) {
  return {type: 'MERGE_WEB_APPS', payload}
}

export function mergeWebAppsDone(payload) {
  return {type: 'MERGE_WEB_APPS_DONE', payload}
}

export function unmergeWebApp(payload) {
  return {type: 'UNMERGE_WEB_APP', payload}
}

export function unmergeWebAppDone(payload) {
  return {type: 'UNMERGE_WEB_APP_DONE', payload}
}

export function deleteUrl(payload) {
  return {type: 'DELETE_URL', payload}
}

export function deleteUrlDone(payload) {
  return {type: 'DELETE_URL_DONE', payload}
}

export function updateWebApp(payload) {
  return {type: 'UPDATE_WEB_APP', payload}
}

export function updateWebAppDone(payload) {
  return {type: 'UPDATE_WEB_APP_DONE', payload}
}

export function verifySession(payload) {
  return {type: 'VERIFY_SESSION', payload}
}

export function verifySessionDone(payload) {
  return {type: 'VERIFY_SESSION_DONE', payload}
}

export function fetchActivity(payload) {
  return {type: 'FETCH_ACTIVITY', payload}
}

export function fetchActivityDone(payload) {
  return {type: 'FETCH_ACTIVITY_DONE', payload}
}

export function fetchEvents(payload) {
  return {type: 'FETCH_EVENTS', payload}
}

export function fetchEventsDone(payload) {
  return {type: 'FETCH_EVENTS_DONE', payload}
}

export function forceCheck(payload) {
  return {type: 'FORCE_CHECK', payload}
}

export function updateNotificationSettings(payload) {
  return {type: 'UPDATE_NOTIFICATION_SETTINGS', payload}
}

export function updateNotificationSettingsDone(payload) {
  return {type: 'UPDATE_NOTIFICATION_SETTINGS_DONE', payload}
}

export function updateFilter(payload) {
  return {type: 'UPDATE_FILTER', payload}
}

export function updateSort(payload) {
  return {type: 'UPDATE_SORT', payload}
}

export function redirect404(payload) {
  return {type: 'REDIRECT_404', payload}
}

export function addWebhook(payload) {
  return {type: 'ADD_WEBHOOK', payload}
}

export function addWebhookDone(payload) {
  return {type: 'ADD_WEBHOOK_DONE', payload}
}

export function updateWebhook(payload) {
  return {type: 'UPDATE_WEBHOOK', payload}
}

export function updateWebhookDone(payload) {
  return {type: 'UPDATE_WEBHOOK_DONE', payload}
}

export function fetchWebhooks(payload) {
  return {type: 'FETCH_WEBHOOKS', payload}
}

export function fetchWebhooksDone(payload) {
  return {type: 'FETCH_WEBHOOKS_DONE', payload}
}

export function testWebhook(payload) {
  return {type: 'TEST_WEBHOOK', payload}
}

export function testWebhookDone(payload) {
  return {type: 'TEST_WEBHOOK_DONE', payload}
}

export function removeWebhook(payload) {
  return {type: 'REMOVE_WEBHOOK', payload}
}

export function removeWebhookDone(payload) {
  return {type: 'REMOVE_WEBHOOK_DONE', payload}
}

export function toggleWebhook(payload) {
  return {type: 'TOGGLE_WEBHOOK', payload}
}

export function toggleWebhookDone(payload) {
  return {type: 'TOGGLE_WEBHOOK_DONE', payload}
}

export function fetchWebhookLogs(payload) {
  return {type: 'FETCH_WEBHOOK_LOGS', payload}
}

export function fetchWebhookLogsDone(payload) {
  return {type: 'FETCH_WEBHOOK_LOGS_DONE', payload}
}

export function fetchAuditLogs(payload) {
  return {type: 'FETCH_AUDIT_LOGS', payload}
}

export function fetchAuditLogsDone(payload) {
  return {type: 'FETCH_AUDIT_LOGS_DONE', payload}
}

export function fetchNextSteps(payload) {
  return {type: 'FETCH_NEXT_STEPS', payload}
}

export function fetchNextStepsDone(payload) {
  return {type: 'FETCH_NEXT_STEPS_DONE', payload}
}

export function fetchCsrf(payload) {
  return {type: 'FETCH_CSRF', payload}
}

export function fetchCsrfDone(payload) {
  return {type: 'FETCH_CSRF_DONE', payload}
}

export function fetchEvidence(payload) {
  return {type: 'FETCH_EVIDENCE', payload}
}

export function fetchEvidenceDone(payload) {
  return {type: 'FETCH_EVIDENCE_DONE', payload}
}

export function updateUserRole(payload) {
  return {type: 'UPDATE_USER_ROLE', payload}
}

export function sendLead(payload) {
  return {type: 'SEND_LEAD', payload}
}

export function fetchAllFindingsCount(payload) {
  return {type: 'FETCH_ALL_FINDINGS_COUNT', payload}
}

export function fetchAllFindingsCountDone(payload) {
  return {type: 'FETCH_ALL_FINDINGS_COUNT_DONE', payload}
}

export function fetchAllFindings(payload) {
  return {type: 'FETCH_ALL_FINDINGS', payload}
}

export function fetchAllFindingsDone(payload) {
  return {type: 'FETCH_ALL_FINDINGS_DONE', payload}
}

export function fetchBulkFindings(payload) {
  return {type: 'FETCH_BULK_FINDINGS', payload}
}

export function fetchBulkFindingsDone(payload) {
  return {type: 'FETCH_BULK_FINDINGS_DONE', payload}
}

export function updateFindingsBulk(payload) {
  return {type: 'UPDATE_FINDINGS_BULK', payload}
}

export function updateFindingsBulkDone(payload) {
  return {type: 'UPDATE_FINDINGS_BULK_DONE', payload}
}

export function fetchFindingsBulkResult(payload) {
  return {type: 'FETCH_FINDINGS_BULK_RESULT', payload}
}

export function fetchFindingsBulkResultDone(payload) {
  return {type: 'FETCH_FINDINGS_BULK_RESULT_DONE', payload}
}

export function fetchNetworkFinding(payload) {
  return {type: 'FETCH_NETWORK_FINDING', payload}
}

export function fetchNetworkFindingDone(payload) {
  return {type: 'FETCH_NETWORK_FINDING_DONE', payload}
}

export function fetchFindingsByNetworkService(payload) {
  return {type: 'FETCH_FINDINGS_BY_NETWORK_SERVICE', payload}
}

export function fetchFindingsByNetworkServiceDone(payload) {
  return {type: 'FETCH_FINDINGS_BY_NETWORK_SERVICE_DONE', payload}
}

export function fetchNetworkImportantFindings(payload) {
  return {type: 'FETCH_NETWORK_IMPORTANT_FINDINGS', payload}
}

export function fetchNetworkImportantFindingsDone(payload) {
  return {type: 'FETCH_NETWORK_IMPORTANT_FINDINGS_DONE', payload}
}

export function fetchNetworkFindingActivity(payload) {
  return {type: 'FETCH_NETWORK_FINDING_ACTIVITY', payload}
}

export function fetchNetworkFindingActivityDone(payload) {
  return {type: 'FETCH_NETWORK_FINDING_ACTIVITY_DONE', payload}
}

export function updateNetworkFinding(payload) {
  return {type: 'UPDATE_NETWORK_FINDING', payload}
}

export function updateNetworkFindingDone(payload) {
  return {type: 'UPDATE_NETWORK_FINDING_DONE', payload}
}

export function fetchNetworkStatsDone(payload) {
  return {type: 'FETCH_NETWORK_STATS_DONE', payload}
}

export function fetchNetworkStats(payload) {
  return {type: 'FETCH_NETWORK_STATS', payload}
}

export function fetchReports(payload) {
  return {type: 'FETCH_REPORTS', payload}
}

export function fetchReportsDone(payload) {
  return {type: 'FETCH_REPORTS_DONE', payload}
}

export function fetchDnsFeed(payload) {
  return {type: 'FETCH_DNS_FEED', payload}
}

export function fetchDnsFeedDone(payload) {
  return {type: 'FETCH_DNS_FEED_DONE', payload}
}

export function fetchDnsFindingDone(payload) {
  return {type: 'FETCH_DNS_FINDING_DONE', payload}
}

export function fetchDnsFinding(payload) {
  return {type: 'FETCH_DNS_FINDING', payload}
}

export function fetchDnsFindingActivityDone(payload) {
  return {type: 'FETCH_DNS_FINDING_ACTIVITY_DONE', payload}
}

export function fetchDnsFindingActivity(payload) {
  return {type: 'FETCH_DNS_FINDING_ACTIVITY', payload}
}

export function updateDnsFindingDone(payload) {
  return {type: 'UPDATE_DNS_FINDING_DONE', payload}
}

export function updateDnsFinding(payload) {
  return {type: 'UPDATE_DNS_FINDING', payload}
}

export function fetchCloudFeed(payload) {
  return {type: 'FETCH_CLOUD_FEED', payload}
}

export function fetchCloudFeedDone(payload) {
  return {type: 'FETCH_CLOUD_FEED_DONE', payload}
}

export function fetchCloudIntegrations(payload) {
  return {type: 'FETCH_CLOUD_INTEGRATIONS', payload}
}

export function fetchCloudIntegrationsDone(payload) {
  return {type: 'FETCH_CLOUD_INTEGRATIONS_DONE', payload}
}

export function fetchActiveCloudIntegrations(payload) {
  return {type: 'FETCH_ACTIVE_CLOUD_INTEGRATIONS', payload}
}

export function fetchActiveCloudIntegrationsDone(payload) {
  return {type: 'FETCH_ACTIVE_CLOUD_INTEGRATIONS_DONE', payload}
}

export function fetchCloudStats(payload) {
  return {type: 'FETCH_CLOUD_STATS', payload}
}

export function fetchCloudStatsDone(payload) {
  return {type: 'FETCH_CLOUD_STATS_DONE', payload}
}

export function fetchHasCloudIntegration(payload) {
  return {type: 'FETCH_HAS_CLOUD_INTEGRATION', payload}
}

export function fetchHasCloudIntegrationDone(payload) {
  return {type: 'FETCH_HAS_CLOUD_INTEGRATION_DONE', payload}
}

/*
 * CLOUD / GCP
 */

export function clearGcpAssetsData(payload) {
  return {type: 'CLEAR_GCP_ASSETS_DATA', payload}
}

export function integrateGcp(payload) {
  return {type: 'INTEGRATE_GCP', payload}
}

export function stopGcpIntegration(payload) {
  return {type: 'STOP_GCP_INTEGRATION', payload}
}

export function fetchGcpStats(payload) {
  return {type: 'FETCH_GCP_STATS', payload}
}

export function fetchGcpStatsDone(payload) {
  return {type: 'FETCH_GCP_STATS_DONE', payload}
}

export function fetchGcpBuckets(payload) {
  return {type: 'FETCH_GCP_BUCKETS', payload}
}

export function fetchGcpBucketsDone(payload) {
  return {type: 'FETCH_GCP_BUCKETS_DONE', payload}
}

export function stopGcpIntegrationDone(payload) {
  return {type: 'STOP_GCP_INTEGRATION_DONE', payload}
}

export function fetchGcpPrincipals(payload) {
  return {type: 'FETCH_GCP_PRINCIPALS', payload}
}

export function fetchGcpPrincipalsDone(payload) {
  return {type: 'FETCH_GCP_PRINCIPALS_DONE', payload}
}

export function fetchGcpServiceAccounts(payload) {
  return {type: 'FETCH_GCP_SERVICE_ACCOUNTS', payload}
}

export function fetchGcpServiceAccountsDone(payload) {
  return {type: 'FETCH_GCP_SERVICE_ACCOUNTS_DONE', payload}
}

export function fetchGcpServiceAccountKeys(payload) {
  return {type: 'FETCH_GCP_SERVICE_ACCOUNT_KEYS', payload}
}

export function fetchGcpServiceAccountKeysDone(payload) {
  return {type: 'FETCH_GCP_SERVICE_ACCOUNT_KEYS_DONE', payload}
}

export function updateGcpFinding(payload) {
  return {type: 'UPDATE_GCP_FINDING', payload}
}

export function updateGcpFindingDone(payload) {
  return {type: 'UPDATE_GCP_FINDING_DONE', payload}
}

export function fetchGcpFinding(payload) {
  return {type: 'FETCH_GCP_FINDING', payload}
}

export function fetchGcpFindingDone(payload) {
  return {type: 'FETCH_GCP_FINDING_DONE', payload}
}

export function fetchGcpFindingActivity(payload) {
  return {type: 'FETCH_GCP_FINDING_ACTIVITY', payload}
}

export function fetchGcpFindingActivityDone(payload) {
  return {type: 'FETCH_GCP_FINDING_ACTIVITY_DONE', payload}
}

export function fetchGcpFindings(payload) {
  return {type: 'FETCH_GCP_FINDINGS', payload}
}

export function fetchGcpFindingsDone(payload) {
  return {type: 'FETCH_GCP_FINDINGS_DONE', payload}
}

export function fetchGcpFindingsByAsset(payload) {
  return {type: 'FETCH_GCP_FINDINGS_BY_ASSET', payload}
}

export function fetchGcpFindingsByAssetDone(payload) {
  return {type: 'FETCH_GCP_FINDINGS_BY_ASSET_DONE', payload}
}

export function updateGcpFindingStatus(payload) {
  return {type: 'UPDATE_GCP_FINDING_STATUS', payload}
}

export function fetchGcpInstances(payload) {
  return {type: 'FETCH_GCP_INSTANCES', payload}
}

export function fetchGcpInstancesDone(payload) {
  return {type: 'FETCH_GCP_INSTANCES_DONE', payload}
}

export function fetchGcpKms(payload) {
  return {type: 'FETCH_GCP_KMS', payload}
}

export function fetchGcpKmsDone(payload) {
  return {type: 'FETCH_GCP_KMS_DONE', payload}
}

export function fetchGcpMostCritical(payload) {
  return {type: 'FETCH_GCP_MOST_CRITICAL', payload}
}

export function fetchGcpMostCriticalDone(payload) {
  return {type: 'FETCH_GCP_MOST_CRITICAL_DONE', payload}
}

export function fetchGcpIntegration(payload) {
  return {type: 'FETCH_GCP_INTEGRATION', payload}
}

export function fetchGcpIntegrationDone(payload) {
  return {type: 'FETCH_GCP_INTEGRATION_DONE', payload}
}

export function fetchGcpIntegrations(payload) {
  return {type: 'FETCH_GCP_INTEGRATIONS', payload}
}

export function fetchGcpIntegrationsDone(payload) {
  return {type: 'FETCH_GCP_INTEGRATIONS_DONE', payload}
}

export function fetchGcpSqlInstances(payload) {
  return {type: 'FETCH_GCP_SQL_INSTANCES', payload}
}

export function fetchGcpSqlInstancesDone(payload) {
  return {type: 'FETCH_GCP_SQL_INSTANCES_DONE', payload}
}

export function updateGcpIntegrationVersion(payload) {
  return {type: 'UPDATE_GCP_INTEGRATION_VERSION', payload}
}

export function updateGcpIntegrationVersionDone(payload) {
  return {type: 'UPDATE_GCP_INTEGRATION_VERSION_DONE', payload}
}

export function retryGcpIntegration(payload) {
  return {type: 'RETRY_GCP_INTEGRATION', payload}
}

export function retryGcpIntegrationDone(payload) {
  return {type: 'RETRY_GCP_INTEGRATION_DONE', payload}
}

/*
 * CLOUD / AWS
 */
export function clearAwsAssetsData(payload) {
  return {type: 'CLEAR_AWS_ASSETS_DATA', payload}
}

export function fetchAwsFinding(payload) {
  return {type: 'FETCH_AWS_FINDING', payload}
}

export function fetchAwsFindingDone(payload) {
  return {type: 'FETCH_AWS_FINDING_DONE', payload}
}

export function fetchAwsFindingActivity(payload) {
  return {type: 'FETCH_AWS_FINDING_ACTIVITY', payload}
}

export function fetchAwsFindingActivityDone(payload) {
  return {type: 'FETCH_AWS_FINDING_ACTIVITY_DONE', payload}
}

export function fetchAwsIntegrations(payload) {
  return {type: 'FETCH_AWS_INTEGRATIONS', payload}
}

export function fetchAwsIntegrationsDone(payload) {
  return {type: 'FETCH_AWS_INTEGRATIONS_DONE', payload}
}

export function fetchAwsStats(payload) {
  return {type: 'FETCH_AWS_STATS', payload}
}

export function fetchAwsStatsDone(payload) {
  return {type: 'FETCH_AWS_STATS_DONE', payload}
}

export function fetchAwsFindings(payload) {
  return {type: 'FETCH_AWS_FINDINGS', payload}
}

export function fetchAwsFindingsDone(payload) {
  return {type: 'FETCH_AWS_FINDINGS_DONE', payload}
}

export function fetchAwsFindingsByAsset(payload) {
  return {type: 'FETCH_AWS_FINDINGS_BY_ASSET', payload}
}

export function fetchAwsFindingsByAssetDone(payload) {
  return {type: 'FETCH_AWS_FINDINGS_BY_ASSET_DONE', payload}
}

export function fetchAwsMostCritical(payload) {
  return {type: 'FETCH_AWS_MOST_CRITICAL', payload}
}

export function fetchAwsMostCriticalDone(payload) {
  return {type: 'FETCH_AWS_MOST_CRITICAL_DONE', payload}
}

export function fetchAwsIamPolicies(payload) {
  return {type: 'FETCH_AWS_IAM_POLICIES', payload}
}

export function fetchAwsIamPoliciesDone(payload) {
  return {type: 'FETCH_AWS_IAM_POLICIES_DONE', payload}
}

export function fetchAwsUsers(payload) {
  return {type: 'FETCH_AWS_USERS', payload}
}

export function fetchAwsUsersDone(payload) {
  return {type: 'FETCH_AWS_USERS_DONE', payload}
}

export function updateAwsFinding(payload) {
  return {type: 'UPDATE_AWS_FINDING', payload}
}

export function updateAwsFindingDone(payload) {
  return {type: 'UPDATE_AWS_FINDING_DONE', payload}
}

export function integrateAws(payload) {
  return {type: 'INTEGRATE_AWS', payload}
}

export function stopAwsIntegration(payload) {
  return {type: 'STOP_AWS_INTEGRATION', payload}
}

export function stopAwsIntegrationDone(payload) {
  return {type: 'STOP_AWS_INTEGRATION_DONE', payload}
}

export function retryAwsIntegration(payload) {
  return {type: 'RETRY_AWS_INTEGRATION', payload}
}

export function retryAwsIntegrationDone(payload) {
  return {type: 'RETRY_AWS_INTEGRATION_DONE', payload}
}

/*
 * CLOUD / AZURE
 */

export function clearAzureServicesData(payload) {
  return {type: 'CLEAR_AZURE_SERVICES_DATA', payload}
}

export function integrateAzure(payload) {
  return {type: 'INTEGRATE_AZURE', payload}
}

export function integrateAzureDone(payload) {
  return {type: 'INTEGRATE_AZURE_DONE', payload}
}

export function stopAzureIntegration(payload) {
  return {type: 'STOP_AZURE_INTEGRATION', payload}
}

export function stopAzureIntegrationDone(payload) {
  return {type: 'STOP_AZURE_INTEGRATION_DONE', payload}
}

export function fetchAzureIntegrations(payload) {
  return {type: 'FETCH_AZURE_INTEGRATIONS', payload}
}

export function fetchAzureIntegrationsDone(payload) {
  return {type: 'FETCH_AZURE_INTEGRATIONS_DONE', payload}
}

export function fetchAzureStats(payload) {
  return {type: 'FETCH_AZURE_STATS', payload}
}

export function fetchAzureStatsDone(payload) {
  return {type: 'FETCH_AZURE_STATS_DONE', payload}
}

export function fetchAzureFindings(payload) {
  return {type: 'FETCH_AZURE_FINDINGS', payload}
}

export function fetchAzureFindingsDone(payload) {
  return {type: 'FETCH_AZURE_FINDINGS_DONE', payload}
}

export function fetchAzureSubscriptions(payload) {
  return {type: 'FETCH_AZURE_SUBSCRIPTIONS', payload}
}

export function fetchAzureSubscriptionsDone(payload) {
  return {type: 'FETCH_AZURE_SUBSCRIPTIONS_DONE', payload}
}

export function fetchAzureBlobContainers(payload) {
  return {type: 'FETCH_AZURE_BLOB_CONTAINERS', payload}
}

export function fetchAzureBlobContainersDone(payload) {
  return {type: 'FETCH_AZURE_BLOB_CONTAINERS_DONE', payload}
}

export function fetchAzureDisks(payload) {
  return {type: 'FETCH_AZURE_DISKS', payload}
}

export function fetchAzureDisksDone(payload) {
  return {type: 'FETCH_AZURE_DISKS_DONE', payload}
}

export function fetchAzureMysqlServers(payload) {
  return {type: 'FETCH_AZURE_MYSQL_SERVERS', payload}
}

export function fetchAzureMysqlServersDone(payload) {
  return {type: 'FETCH_AZURE_MYSQL_SERVERS_DONE', payload}
}

export function fetchAzurePostgresqlServers(payload) {
  return {type: 'FETCH_AZURE_POSTGRESQL_SERVERS', payload}
}

export function fetchAzurePostgresqlServersDone(payload) {
  return {type: 'FETCH_AZURE_POSTGRESQL_SERVERS_DONE', payload}
}

export function fetchAzureStorageAccounts(payload) {
  return {type: 'FETCH_AZURE_STORAGE_ACCOUNTS', payload}
}

export function fetchAzureStorageAccountsDone(payload) {
  return {type: 'FETCH_AZURE_STORAGE_ACCOUNTS_DONE', payload}
}

export function fetchAzureResourceGroups(payload) {
  return {type: 'FETCH_AZURE_RESOURCE_GROUPS', payload}
}

export function fetchAzureResourceGroupsDone(payload) {
  return {type: 'FETCH_AZURE_RESOURCE_GROUPS_DONE', payload}
}

export function fetchAzureSqlDatabases(payload) {
  return {type: 'FETCH_AZURE_SQL_DATABASES', payload}
}

export function fetchAzureSqlDatabasesDone(payload) {
  return {type: 'FETCH_AZURE_SQL_DATABASES_DONE', payload}
}

export function fetchAzureSqlServers(payload) {
  return {type: 'FETCH_AZURE_SQL_SERVERS', payload}
}

export function fetchAzureSqlServersDone(payload) {
  return {type: 'FETCH_AZURE_SQL_SERVERS_DONE', payload}
}

export function fetchAzureVmInstances(payload) {
  return {type: 'FETCH_AZURE_VM_INSTANCES', payload}
}

export function fetchAzureVmInstancesDone(payload) {
  return {type: 'FETCH_AZURE_VM_INSTANCES_DONE', payload}
}

export function fetchAzureMostCritical(payload) {
  return {type: 'FETCH_AZURE_MOST_CRITICAL', payload}
}

export function fetchAzureMostCriticalDone(payload) {
  return {type: 'FETCH_AZURE_MOST_CRITICAL_DONE', payload}
}

export function fetchAzureFinding(payload) {
  return {type: 'FETCH_AZURE_FINDING', payload}
}

export function fetchAzureFindingDone(payload) {
  return {type: 'FETCH_AZURE_FINDING_DONE', payload}
}

export function fetchAzureFindingsByResource(payload) {
  return {type: 'FETCH_AZURE_FINDINGS_BY_RESOURCE', payload}
}

export function fetchAzureFindingsByResourceDone(payload) {
  return {type: 'FETCH_AZURE_FINDINGS_BY_RESOURCE_DONE', payload}
}

export function fetchAzureFindingsByService(payload) {
  return {type: 'FETCH_AZURE_FINDINGS_BY_SERVICE', payload}
}

export function fetchAzureFindingsByServiceDone(payload) {
  return {type: 'FETCH_AZURE_FINDINGS_BY_SERVICE_DONE', payload}
}

export function fetchAzureFindingActivity(payload) {
  return {type: 'FETCH_AZURE_FINDING_ACTIVITY', payload}
}

export function fetchAzureFindingActivityDone(payload) {
  return {type: 'FETCH_AZURE_FINDING_ACTIVITY_DONE', payload}
}

export function updateAzureFindingStatus(payload) {
  return {type: 'UPDATE_AZURE_FINDING_STATUS', payload}
}

export function updateAzureFindingStatusDone(payload) {
  return {type: 'UPDATE_AZURE_FINDING_STATUS_DONE', payload}
}

/*
 * MICROSOFT 365
 */

export function fetchMicrosoft365Integrations(payload) {
  return {type: 'FETCH_MICROSOFT_365_INTEGRATIONS', payload}
}

export function fetchMicrosoft365IntegrationsDone(payload) {
  return {type: 'FETCH_MICROSOFT_365_INTEGRATIONS_DONE', payload}
}

export function removeMicrosoft365Integration(payload) {
  return {type: 'REMOVE_MICROSOFT_365_INTEGRATION', payload}
}

export function removeMicrosoft365IntegrationDone(payload) {
  return {type: 'REMOVE_MICROSOFT_365_INTEGRATION_DONE', payload}
}

export function fetchMicrosoft365Authorization(payload) {
  return {type: 'FETCH_MICROSOFT_365_AUTHORIZATION', payload}
}

/*
 * GOOGLE WORKSPACE
 */
export function removeWorkspaceIntegration(payload) {
  return {type: 'REMOVE_WORKSPACE_INTEGRATION', payload}
}

export function removeWorkspaceIntegrationDone(payload) {
  return {type: 'REMOVE_WORKSPACE_INTEGRATION_DONE', payload}
}

export function fetchGoogleWorkspaceIntegrations(payload) {
  return {type: 'FETCH_GOOGLE_WORKSPACE_INTEGRATIONS', payload}
}

export function fetchGoogleWorkspaceIntegrationsDone(payload) {
  return {type: 'FETCH_GOOGLE_WORKSPACE_INTEGRATIONS_DONE', payload}
}

export function fetchCompanyEmails(payload) {
  return {type: 'FETCH_COMPANY_EMAILS', payload}
}

export function fetchCompanyEmailsDone(payload) {
  return {type: 'FETCH_COMPANY_EMAILS_DONE', payload}
}

export function fetchGoogleWorkspaceFinding(payload) {
  return {type: 'FETCH_GOOGLE_WORKSPACE_FINDING', payload}
}

export function fetchGoogleWorkspaceFindingDone(payload) {
  return {type: 'FETCH_GOOGLE_WORKSPACE_FINDING_DONE', payload}
}

export function fetchGoogleWorkspaceFindingActivity(payload) {
  return {type: 'FETCH_GOOGLE_WORKSPACE_FINDING_ACTIVITY', payload}
}

export function fetchGoogleWorkspaceFindingActivityDone(payload) {
  return {type: 'FETCH_GOOGLE_WORKSPACE_FINDING_ACTIVITY_DONE', payload}
}

export function updateGoogleWorkspaceFindingStatus(payload) {
  return {type: 'UPDATE_GOOGLE_WORKSPACE_FINDING_STATUS', payload}
}

export function updateGoogleWorkspaceFindingStatusDone(payload) {
  return {type: 'UPDATE_GOOGLE_WORKSPACE_FINDING_STATUS_DONE', payload}
}

export function fetchGoogleWorkspaceAuthorization(payload) {
  return {type: 'FETCH_GOOGLE_WORKSPACE_AUTHORIZATION', payload}
}

export function updateGoogleWorkspaceIntegration(payload) {
  return {type: 'UPDATE_GOOGLE_WORKSPACE_INTEGRATION', payload}
}

// COMPLIANCE

export function fetchComplianceStats(payload) {
  return {type: 'FETCH_COMPLIANCE_STATS', payload}
}

export function fetchComplianceStatsDone(payload) {
  return {type: 'FETCH_COMPLIANCE_STATS_DONE', payload}
}

// LGPD

export function addTagToAsset(payload) {
  return {type: 'ADD_TAG_TO_ASSET', payload}
}

export function removeTagFromAsset(payload) {
  return {type: 'REMOVE_TAG_FROM_ASSET', payload}
}

export function fetchLgpdIntegrations(payload) {
  return {type: 'FETCH_LGPD_INTEGRATIONS', payload}
}

export function fetchLgpdIntegrationsDone(payload) {
  return {type: 'FETCH_LGPD_INTEGRATIONS_DONE', payload}
}

export function fetchLgpdDetails(payload) {
  return {type: 'FETCH_LGPD_DETAILS', payload}
}

export function fetchLgpdDetailsDone(payload) {
  return {type: 'FETCH_LGPD_DETAILS_DONE', payload}
}

export function fetchLgpdItemDetails(payload) {
  return {type: 'FETCH_LGPD_ITEM_DETAILS', payload}
}

export function fetchLgpdItemDetailsDone(payload) {
  return {type: 'FETCH_LGPD_ITEM_DETAILS_DONE', payload}
}

export function fetchLgpdFindings(payload) {
  return {type: 'FETCH_LGPD_FINDINGS', payload}
}

export function fetchLgpdFindingsDone(payload) {
  return {type: 'FETCH_LGPD_FINDINGS_DONE', payload}
}

export function startLgpdMonitoring(payload) {
  return {type: 'START_LGPD_MONITORING', payload}
}

export function startLgpdMonitoringDone(payload) {
  return {type: 'START_LGPD_MONITORING_DONE', payload}
}

export function reload(payload) {
  return {type: 'RELOAD', payload}
}

export function fetchScoreOverTime(payload) {
  return {type: 'FETCH_SCORE_OVER_TIME', payload}
}

export function fetchScoreOverTimeDone(payload) {
  return {type: 'FETCH_SCORE_OVER_TIME_DONE', payload}
}

export function fetchBreachesOverTime(payload) {
  return {type: 'FETCH_BREACHES_OVER_TIME', payload}
}

export function fetchBreachesOverTimeDone(payload) {
  return {type: 'FETCH_BREACHES_OVER_TIME_DONE', payload}
}

// Suppliers

export function fetchSuppliers(payload) {
  return {type: 'FETCH_SUPPLIERS', payload}
}

export function fetchSuppliersDone(payload) {
  return {type: 'FETCH_SUPPLIERS_DONE', payload}
}

export function updateSupplierDone(payload) {
  return {type: 'UPDATE_SUPPLIER_DONE', payload}
}

export function fetchSupplierDetails(payload) {
  return {type: 'FETCH_SUPPLIER_DETAILS', payload}
}

export function fetchSupplierDetailsDone(payload) {
  return {type: 'FETCH_SUPPLIER_DETAILS_DONE', payload}
}

export function resendSupplierInvite(payload) {
  return {type: 'RESEND_SUPPLIER_INVITE', payload}
}

export function addSupplier(payload) {
  return {type: 'ADD_SUPPLIER', payload}
}

export function addSupplierDone(payload) {
  return {type: 'ADD_SUPPLIER_DONE', payload}
}

export function updateSupplier(payload) {
  return {type: 'UPDATE_SUPPLIER', payload}
}

export function removeSupplier(payload) {
  return {type: 'REMOVE_SUPPLIER', payload}
}

// Questionnaires

export function fetchQuestionnaires(payload) {
  return {type: 'FETCH_QUESTIONNAIRES', payload}
}

export function fetchQuestionnairesDone(payload) {
  return {type: 'FETCH_QUESTIONNAIRES_DONE', payload}
}

export function fetchQuestionnaireDetails(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_DETAILS', payload}
}

export function fetchQuestionnaireDetailsDone(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_DETAILS_DONE', payload}
}

export function fetchQuestionnaireBaseDetailsDone(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_BASE_DETAILS_DONE', payload}
}

export function updateQuestionnaireAnswer(payload) {
  return {type: 'UPDATE_QUESTIONNAIRE_ANSWER', payload}
}

export function uploadQuestionnaire(payload) {
  return {type: 'UPLOAD_QUESTIONNAIRE', payload}
}

export function uploadQuestionnaireDone(payload) {
  return {type: 'UPLOAD_QUESTIONNAIRE_DONE', payload}
}

export function updateQuestionnaire(payload) {
  return {type: 'UPDATE_QUESTIONNAIRE', payload}
}

export function fetchSupplierInvites(payload) {
  return {type: 'FETCH_SUPPLIER_INVITES', payload}
}

export function fetchSupplierInvitesDone(payload) {
  return {type: 'FETCH_SUPPLIER_INVITES_DONE', payload}
}

export function verifySupplierInvite(payload) {
  return {type: 'VERIFY_SUPPLIER_INVITE', payload}
}

export function verifySupplierInviteDone(payload) {
  return {type: 'VERIFY_SUPPLIER_INVITE_DONE', payload}
}

export function respondSupplierInvite(payload) {
  return {type: 'RESPOND_SUPPLIER_INVITE', payload}
}

export function fetchQuestionnaireInvites(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_INVITES', payload}
}

export function fetchQuestionnaireInvitesDone(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_INVITES_DONE', payload}
}

export function respondQuestionnaireInvite(payload) {
  return {type: 'RESPOND_QUESTIONNAIRE_INVITE', payload}
}

export function fetchSupplierSentQuestionnaires(payload) {
  return {type: 'FETCH_SUPPLIER_SENT_QUESTIONNAIRES', payload}
}

export function fetchSupplierSentQuestionnairesDone(payload) {
  return {type: 'FETCH_SUPPLIER_SENT_QUESTIONNAIRES_DONE', payload}
}

export function fetchSupplierQuestionnaire(payload) {
  return {type: 'FETCH_SUPPLIER_QUESTIONNAIRE', payload}
}

export function fetchSupplierQuestionnaireDone(payload) {
  return {type: 'FETCH_SUPPLIER_QUESTIONNAIRE_DONE', payload}
}

export function answerReview(payload) {
  return {type: 'ANSWER_REVIEW', payload}
}

export function answersReviewFeedback(payload) {
  return {type: 'ANSWERS_REVIEW_FEEDBACK', payload}
}

export function clearAnswerReviewFeedback(payload) {
  return {type: 'CLEAR_ANSWER_REVIEW_FEEDBACK', payload}
}

export function questionnaireReview(payload) {
  return {type: 'QUESTIONNAIRE_REVIEW', payload}
}

export function questionnaireReviewDone(payload) {
  return {type: 'QUESTIONNAIRE_REVIEW_DONE', payload}
}

export function fetchQuestionnaireEvents(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_EVENTS', payload}
}

export function fetchQuestionnaireEventsDone(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_EVENTS_DONE', payload}
}

export function answerFeedback(payload) {
  return {type: 'ANSWER_FEEDBACK', payload}
}

export function clearAnswerFeedback(payload) {
  return {type: 'CLEAR_ANSWER_FEEDBACK', payload}
}

export function removeQuestionnaire(payload) {
  return {type: 'REMOVE_QUESTIONNAIRE', payload}
}

export function removeSentQuestionnaire(payload) {
  return {type: 'REMOVE_SENT_QUESTIONNAIRE', payload}
}

export function generateAnswer(payload) {
  return {type: 'GENERATE_ANSWER', payload}
}

export function generateAnswerDone(payload) {
  return {type: 'GENERATE_ANSWER_DONE', payload}
}

export function fetchQuestionReferences(payload) {
  return {type: 'FETCH_QUESTION_REFERENCES', payload}
}

export function fetchQuestionReferencesDone(payload) {
  return {type: 'FETCH_QUESTION_REFERENCES_DONE', payload}
}

// Questionnaires - Templates

export function fetchQuestionnaireTemplates(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_TEMPLATES', payload}
}

export function fetchQuestionnaireTemplatesDone(payload) {
  return {type: 'FETCH_QUESTIONNAIRE_TEMPLATES_DONE', payload}
}

export function uploadQuestionnaireTemplate(payload) {
  return {type: 'UPLOAD_QUESTIONNAIRE_TEMPLATE', payload}
}

export function sendQuestionnaire(payload) {
  return {type: 'SEND_QUESTIONNAIRE', payload}
}

export function sendQuestionnaireDone(payload) {
  return {type: 'SEND_QUESTIONNAIRE_DONE', payload}
}

export function updateQuestionnaireTemplate(payload) {
  return {type: 'UPDATE_QUESTIONNAIRE_TEMPLATE', payload}
}

export function removeQuestionnaireTemplate(payload) {
  return {type: 'REMOVE_QUESTIONNAIRE_TEMPLATE', payload}
}

// Documents
export function fetchDocuments(payload) {
  return {type: 'FETCH_DOCUMENTS', payload}
}

export function fetchDocumentsDone(payload) {
  return {type: 'FETCH_DOCUMENTS_DONE', payload}
}

export function uploadDocument(payload) {
  return {type: 'UPLOAD_DOCUMENT', payload}
}

export function uploadDocumentDone(payload) {
  return {type: 'UPLOAD_DOCUMENT_DONE', payload}
}

export function removeDocument(payload) {
  return {type: 'REMOVE_DOCUMENT', payload}
}

export function removeDocumentFeedback(payload) {
  return {type: 'REMOVE_DOCUMENT_FEEDBACK', payload}
}

export function fetchProducts(payload) {
  return {type: 'FETCH_PRODUCTS', payload}
}

export function fetchProductsDone(payload) {
  return {type: 'FETCH_PRODUCTS_DONE', payload}
}

export function fetchProduct(payload) {
  return {type: 'FETCH_PRODUCT', payload}
}

export function fetchProductDone(payload) {
  return {type: 'FETCH_PRODUCT_DONE', payload}
}

export function requestQuote(payload) {
  return {type: 'REQUEST_QUOTE', payload}
}

export function requestQuoteDone(payload) {
  return {type: 'REQUEST_QUOTE_DONE', payload}
}
