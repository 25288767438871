import PropTypes from 'prop-types'
import {
  addWebhook,
  clearError,
  genericError,
  loading,
  removeWebhook,
  testWebhook,
  updateWebhook,
} from 'actions'
import React, {useState, useEffect} from 'react'
import {CustomHeaders} from './WebhookCustomHeaders'
import {useNavigate, Link} from 'react-router-dom'
import {
  labelStyle,
  newFindingsAlert,
  testWebhookFeedbackList,
  tooltipStyle,
} from 'constant'
import {useDispatch, useSelector} from 'react-redux'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import WebhookIcon from 'res/icons/webhookIcon'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import AlternateActionButton from 'components/partials/buttons/AlternateActionButton'
import {i18next} from 'translate/i18n'
import CopyToClipboard from 'components/partials/CopyToClipboard'
import {isLoading} from 'utils'

export function WebhookForm(props) {
  const csrfState = useSelector(state => state.csrf)
  const errorState = useSelector(state => state.error)
  const successState = useSelector(state => state.success)
  const loadingState = useSelector(state => state.loading)
  const webhookTestedState = useSelector(state => state.webhookTested)
  const lastStatusState = useSelector(state => state.lastStatus)
  const webhookTestsCountState = useSelector(state => state.webhookTestsCount)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    condition: props.webhook ? props.webhook.condition : 'highOrCritical',
    enabled: true,
    enabledEncryption: false,
  })
  const [customHeaders, setCustomHeaders] = useState([])
  const [lastStatus, setLastStatus] = useState(null)
  const [webhookTested, setWebhookTested] = useState(false)
  const [testFeedback, setTestFeedback] = useState('neutral')

  const handleSubmit = e => {
    e.preventDefault()

    const headers = getHeadersFormated()

    if (props.addScreen)
      dispatch(
        addWebhook({
          ...form,
          headers,
          _csrf: csrfState,
        })
      )
    else
      dispatch(
        updateWebhook({
          ...form,
          headers,
          _csrf: csrfState,
        })
      )
  }

  const handleInputChange = e => {
    const {name, value} = e.target
    setForm({...form, [name]: value})
    setWebhookTested(false)
    setLastStatus(false)
  }

  const handleSwitch = e => {
    const name = e.target.name
    const isChecked = e.target.checked

    if (name === 'enabledEncryption') {
      setWebhookTested(false)

      const encryptionPayload = {
        iv: generateId(32),
        key: generateId(64),
      }

      setForm({
        ...form,
        [name]: isChecked,
        encryption: isChecked ? encryptionPayload : null,
      })
    } else {
      setForm({...form, [name]: isChecked})
    }
  }

  const handleRemove = () => {
    if (window.confirm(`Are you sure you want to remove this webhook?`))
      dispatch(
        removeWebhook({
          ...props.webhookSelected,
          _csrf: csrfState,
        })
      )
  }

  const handleTestWebhook = () => {
    if (!form.condition)
      return dispatch(
        genericError({
          message: i18next.t('settings.errors.selectACondition'),
        })
      )

    if (!form.name || !form.url)
      return dispatch(
        genericError({
          message: i18next.t('settings.errors.requiredFields'),
        })
      )

    setWebhookTested(false)
    dispatch(loading({testingWebhook: true}))

    dispatch(
      testWebhook({
        ...form,
        headers: getHeadersFormated(),
        _csrf: csrfState,
      })
    )
  }

  const getHeadersFormated = () => {
    const headers = {}
    customHeaders.forEach(header => {
      headers[header.name] = header.value
    })
    return headers
  }

  const handleGenerateKey = () => {
    setWebhookTested(false)
    setForm({
      ...form,
      encryption: {
        iv: generateId(32),
        key: generateId(64),
      },
    })
  }

  const fallbackCopyTextToClipboard = function (text) {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  const handleCopyToClipboard = function (text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!')
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }

  const dec2hex = function (dec) {
    return dec.toString(16).padStart(2, '0')
  }

  const generateId = function (len) {
    const arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }

  const handleSelectChange = event => {
    const {value} = event
    setForm({...form, condition: value})
  }

  useEffect(() => {
    setWebhookTested(webhookTestedState)
    setLastStatus(lastStatusState)
    dispatch(clearError())
  }, [webhookTestedState, lastStatusState, webhookTestsCountState, dispatch])

  useEffect(() => {
    const headers = []

    if (props.webhook)
      for (const key in props.webhook.headers) {
        headers.push({
          name: key,
          value: props.webhook.headers[key],
        })
      }

    setForm({
      ...form,
      ...props.webhook,
      enabledEncryption: props.webhook
        ? props.webhook.encryption !== null
        : false,
      headers,
    })
    setCustomHeaders(headers)
  }, [props.webhook])

  useEffect(() => {
    let currentStatus

    const isTestingWebhook = isLoading(loadingState.testingWebhook, false)

    if (isTestingWebhook) {
      currentStatus = 'loading'
    } else if (webhookTested && !lastStatus.success && !isTestingWebhook) {
      currentStatus = 'failed'
    } else if (webhookTested && lastStatus.success && !isTestingWebhook) {
      currentStatus = 'success'
    } else {
      currentStatus = 'neutral'
    }

    setTestFeedback(
      testWebhookFeedbackList.find(({status}) => status === currentStatus)
    )
  }, [lastStatus, webhookTested])

  useEffect(() => {
    setWebhookTested(false)
  }, [])

  useEffect(() => {
    if (errorState) return

    if ((successState && webhookTested) || !form.enabled) {
      props.showAddScreen(false)
      props.selectWebhook(null)
    }
  }, [errorState, successState])

  const handleSelectDefaultValue = () => {
    const index = newFindingsAlert.findIndex(
      item => item.value === form.condition
    )

    return newFindingsAlert[index]
  }

  const isSaveButtonDisabled =
    !(webhookTested && lastStatus.success) && form.enabled

  return (
    <>
      <div className="flex w-full">
        <div className="hidden sm:block w-4/12">
          <p className="text-sm text-sideral-900 leading-none">
            {i18next.t('titles.newWebhook')}
          </p>

          <p className="text-light text-xs text-sideral-300 pt-1">
            {i18next.t('subtitles.newWebhook')}
          </p>

          <SecondaryButton
            text={i18next.t('buttons.needHelp')}
            size="sm"
            onClick={() => navigate('#/help/webhooks')}
            margin="mt-4"
          />
        </div>
        <div className="w-full sm:w-5/12">
          <form onSubmit={handleSubmit}>
            <PrimaryInput
              text={i18next.t('labels.name') + '*'}
              name="name"
              value={form.name || ''}
              type="text"
              required={true}
              onChange={handleInputChange}
              margin="mb-8"
            />
            <div className="flex flex-wrap">
              <label className="w-full block tracking-wide text-sideral-700 text-xs font-medium mb-1">
                URL*
              </label>
              <div className="w-full flex flex-nowrap mb-8">
                <span className="items-center p-3 rounded-none rounded-l border border-r-0 border-sideral-100 bg-sideral-50 text-gray-500 text-sm">
                  https://
                </span>
                <input
                  className="appearance-none block w-full text-ink border-l rounded-r rounded-none border border-sideral-100 roboto leading-tight focus:outline-none focus:bg-white p-3"
                  type="text"
                  required
                  placeholder="yourendpoint.com"
                  name="url"
                  value={
                    (form.url && form.url.replace(/https?:\/\//, '')) || ''
                  }
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <PrimaryInput
              text="HTTP Basic Auth Username"
              name="basicAuthUsername"
              value={form.basicAuthUsername || ''}
              type="text"
              onChange={handleInputChange}
              margin="mb-8"
            />
            <PrimaryInput
              text="HTTP Basic Auth Password"
              name="basicAuthPassword"
              value={form.basicAuthPassword || ''}
              type="text"
              onChange={handleInputChange}
              margin="mb-8"
            />
            <div className="mb-8">
              <label className="w-full block tracking-wide text-sideral-700 text-xs font-medium mb-1">
                {i18next.t('labels.customHeaders')}
              </label>
              <CustomHeaders
                customHeaders={
                  customHeaders.length ? customHeaders : form.headers
                }
                setCustomHeaders={setCustomHeaders}
                setWebhookTested={setWebhookTested}
              />
            </div>
            <div className="mb-8">
              <label className="w-full block tracking-wide text-sideral-700 text-xs font-medium mb-1">
                {i18next.t('labels.condition')}
              </label>
              <PrimarySelect
                onChange={handleSelectChange}
                defaultValue={handleSelectDefaultValue()}
                name="email.condition"
                options={newFindingsAlert}
                placeholder={i18next.t('placeholders.select')}
              />
            </div>
            <div className="mb-8 text-xs">
              <label className={labelStyle}>
                {i18next.t('labels.enableWebhook')}
              </label>
              <input
                checked={form.enabled}
                className="switch switch--shadow"
                id="switch-shadow-enable-webhook"
                onChange={handleSwitch}
                type="checkbox"
                name="enabled"
              />
              <label
                htmlFor="switch-shadow-enable-webhook"
                className="mt-2"></label>
            </div>
            <div className="mb-8 text-xs">
              <label className={labelStyle}>
                {i18next.t('labels.enableEncryption')} (AES-256-CBC)
              </label>
              <input
                checked={form.enabledEncryption}
                className="switch switch--shadow"
                id="switch-shadow-enable-encryption"
                onChange={handleSwitch}
                type="checkbox"
                name="enabledEncryption"
              />
              <label
                htmlFor="switch-shadow-enable-encryption"
                className="mt-2"></label>
            </div>

            {form.enabledEncryption ? (
              <>
                <div className="w-full">
                  <label className={labelStyle}>
                    {i18next.t('labels.encryptionKey')}
                  </label>

                  <CopyToClipboard
                    content={form.encryption?.key}
                    onClick={() => handleCopyToClipboard(form.encryption.key)}
                    classes="my-3"
                  />

                  <SecondaryButton
                    text={i18next.t('buttons.generateNewKey')}
                    onClick={handleGenerateKey}
                    margin="mt-1"
                    size="sm"
                  />

                  <div className="float-right mt-1 mb-8">
                    <Link to="#/help/webhooks" className="text-sky-500 text-xs">
                      {i18next.t('buttons.needHelp')}
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              className={`${testFeedback.theme} flex items-center w-full text-sm rounded-md border p-4 mb-8`}>
              <figure className="hidden lg:block w-20 h-20">
                <WebhookIcon width="80" height="80" color="#455A64" />
              </figure>
              <div className="ml-0 lg:ml-8">
                <h3 className="font-medium">{testFeedback.title}</h3>
                <p className="font-light text-sm text-sideral-900 pt-1">
                  {testFeedback.body}
                </p>
                {(testFeedback.status === 'neutral' ||
                  testFeedback.status === 'failed') && (
                  <SecondaryButton
                    type="button"
                    text={i18next.t('buttons.testWebhook')}
                    margin="mt-2"
                    onClick={handleTestWebhook}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-end w-full ">
        {!props.addScreen ? (
          <AlternateActionButton
            text={i18next.t('buttons.removeWebhook')}
            onClick={handleRemove}
            size="md"
            trashIcon
          />
        ) : (
          ''
        )}

        <div className="tooltip">
          <PrimaryButton
            onClick={handleSubmit}
            text={
              props.webhookSelected
                ? i18next.t('buttons.saveChanges')
                : i18next.t('buttons.createWebhook')
            }
            disabled={isSaveButtonDisabled}
            margin="ml-8"
          />

          {isSaveButtonDisabled && (
            <div
              className={`${tooltipStyle.default} text-xs w-56 mt-1 transform -translate-x-[30px]`}>
              {props.webhookSelected
                ? i18next.t('buttons.saveChangesWebhookTooltip')
                : i18next.t('buttons.createWebhookTooltip')}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

WebhookForm.propTypes = {
  addScreen: PropTypes.bool,
  selectWebhook: PropTypes.func,
  showAddScreen: PropTypes.func,
  webhook: PropTypes.object,
  webhookSelected: PropTypes.object,
}
