import fetchIntercept from 'fetch-intercept'
import {isPublicView} from 'utils'
import {takeEvery, all, put} from 'redux-saga/effects'
import config from 'config'

import {genericError, updateSort} from 'actions'

import {
  handleFetchAllFindings,
  handleFetchAllFindingsCount,
  handleFetchBulkFindings,
  handleFetchFindingsBulkResult,
  handleUpdateFindingAssignee,
  handleUpdateFindingsBulk,
} from './finding'

import {
  handleAddWebhook,
  handleFetchWebhookLogs,
  handleFetchWebhooks,
  handleRemoveWebhook,
  handleTestWebhook,
  handleToggleWebhook,
  handleUpdateWebhook,
} from './webhooks'

import {handleDeleteUrl, handleForceCheck} from './service'

import {handleFetchCloudFeed, handleFetchActiveCloudIntegrations} from './cloud'

import {
  handleFetchGcpBuckets,
  handleFetchGcpFetchServiceAccounts,
  handleFetchGcpFinding,
  handleFetchGcpFindingActivity,
  handleFetchGcpFindings,
  handleFetchGcpInstances,
  handleFetchGcpIntegrations,
  handleFetchGcpKms,
  handleFetchGcpMostCritical,
  handleFetchGcpPrincipals,
  handleFetchGcpSqlInstances,
  handleFetchGcpStats,
  handleFetchGcpIntegration,
  handleIntegrateGcp,
  handleRetryGcpIntegration,
  handleStopGcpIntegration,
  handleUpdateGcpFinding,
  handleUpdateGcpIntegrationVersion,
  handleFetchGcpServiceAccountKeys,
  handleFetchGcpFindingsByAsset,
} from './gcp'

import {
  handleFetchAzureBlobContainers,
  handleFetchAzureDisks,
  handleFetchAzureFinding,
  handleFetchAzureFindingActivity,
  handleFetchAzureFindings,
  handleFetchAzureFindingsByResource,
  handleFetchAzureFindingsByService,
  handleFetchAzureIntegrations,
  handleFetchAzureMostCritical,
  handleFetchAzureMysqlServers,
  handleFetchAzurePostgresqlServers,
  handleFetchAzureResourceGroups,
  handleFetchAzureSqlDatabases,
  handleFetchAzureSqlServers,
  handleFetchAzureStats,
  handleFetchAzureStorageAccounts,
  handleFetchAzureSubscriptions,
  handleFetchAzureVmInstances,
  handleIntegrateAzure,
  handleStopAzureIntegration,
  handleUpdateAzureFindingStatus,
} from './azure'

import {
  handleFetchActivity,
  handleFetchEvidence,
  handleFetchFeed,
  handleFetchIssues,
  handleUpdateIssueStatus,
  handleFetchTotalVsClosedIssues,
} from './issue'

import {handleFetchHost, handleFetchHostFindings} from './host'

import {
  handleAddDomain,
  handleFetchDnsFeed,
  handleFetchDnsFinding,
  handleFetchDnsFindingActivity,
  handleFetchDnsStats,
  handleFetchDomain,
  handleFetchDomainFindings,
  handleFetchDomainRecords,
  handleFetchDomainSubdomains,
  handleFetchDomains,
  handleRemoveDomain,
  handleUpdateDnsFinding,
  handleFetchDnsRecordsLogs,
  handleFetchNameServers,
  handleFetchNameServerDetails,
  handleFetchDomainsByRegistrar,
  handleFetchDomainsHostnames,
} from './dns'

import {
  handleFetchFindingsByNetworkService,
  handleFetchHosts,
  handleFetchNetworkFinding,
  handleFetchNetworkFindingActivity,
  handleFetchNetworkImportantFindings,
  handleFetchNetworkStats,
  handleFetchService,
  handleFetchServices,
  handleUpdateNetworkFinding,
  handleFetchHostsByNetworkService,
  handleFetchHostsByAsOrgs,
  handleFetchHostsByCountry,
  handleFetchServiceDetails,
  handleFetchServiceDetailsFindings,
} from './network'

import {
  handleAddWebApp,
  handleFetchFingerprintDetails,
  handleFetchWebApp,
  handleFetchWebAppFindings,
  handleFetchWebApps,
  handleFetchWebAppsByProtocol,
  handleFetchWebAppsFingerprints,
  handleFetchWebFinding,
  handleFetchWebFindingActivity,
  handleFetchWebImportantFindings,
  handleFetchWebStats,
  handleUpdateWebFinding,
} from './web'

import {
  handleFetchBreachedAccounts,
  handleFetchBreaches,
  handleFetchPeopleAccount,
  handleFetchPeopleAccountFindings,
  handleFetchPeopleFinding,
  handleUpdatePeopleFindingStatus,
  handleFetchPeopleFindingActivity,
} from './people'

import {
  handleAcceptInvite,
  handleAcceptTerms,
  handleCancelInvite,
  handleChangeCompany,
  handleCompanyRenewal,
  handleDeleteMember,
  handleFetchAuditLogs,
  handleFetchBreachesOverTime,
  handleFetchCloudIntegrations,
  handleFetchCloudStats,
  handleFetchCompaniesPagination,
  handleFetchCompany,
  handleFetchCompanyMembers,
  handleFetchCompanyScores,
  handleFetchCompanyStats,
  handleFetchEvents,
  handleFetchHasCloudIntegration,
  handleFetchInvites,
  handleFetchReports,
  handleFetchScoreOverTime,
  handleGenerateReport,
  handleInviteInfo,
  handleInviteMember,
  handleSendLead,
  handleSubmitSurvey,
  handleToggleCompanyMonitoring,
  handleUpdateCompany,
} from './company'

import {
  handleMergeWebApps,
  handleUnmergeWebApp,
  handleUpdateWebApp,
} from './webapp'

import {handleFetchDomainsBreakdown} from './asm'

import {
  handleAuthenticate,
  handleCheck2fa,
  handleCompleteGuide,
  handleDisable2fa,
  handleDisconnectGoogle,
  handleDisconnectMicrosoft,
  handleEnable2fa,
  handleFetchCsrf,
  handleFetchNextSteps,
  handleFetchUser,
  handleForgotPassword,
  handleGenerate2fa,
  handleLogout,
  handleRegister,
  handleResetPassword,
  handleSaveProfile,
  handleSignUp,
  handleUpdateNotificationSettings,
  handleUpdatePassword,
  handleUpdateUserRole,
  handleVerifySession,
} from './auth'

import {
  handleFetchApiKeys,
  handleGenerateApiKey,
  handleRemoveApiKey,
} from './apiKeys'

import {
  handleFetchAwsFinding,
  handleFetchAwsFindings,
  handleFetchAwsIntegrations,
  handleFetchAwsMostCritical,
  handleFetchAwsStats,
  handleFetchAwsIamPolicies,
  handleFetchAwsUsers,
  handleFetchEbsVolumes,
  handleFetchKmsKeys,
  handleFetchEc2Amis,
  handleFetchEc2Instances,
  handleFetchEc2SecurityGroups,
  handleFetchEc2Vpcs,
  handleFetchEfs,
  handleFetchRdsInstances,
  handleFetchRdsSnapshots,
  handleFetchS3Buckets,
  handleIntegrateAws,
  handleStopAwsIntegration,
  handleFetchAwsFindingActivity,
  handleUpdateAwsFinding,
  handleFetchAwsFindingsByAsset,
  handleFetchRoute53Domains,
  handleFetchRoute53HostedZones,
  handleFetchCloudtrailTrails,
  handleRetryAwsIntegration,
} from './aws'

import {
  handleFetchGoogleWorkspaceIntegrations,
  handleFetchCompanyEmails,
  handleFetchGoogleWorkspaceFinding,
  handleFetchGoogleWorkspaceFindingActivity,
  handleUpdateGoogleWorkspaceFindingStatus,
  handleRemoveWorkspaceIntegration,
  handleFetchGoogleWorkspaceAuthorization,
  handleUpdateGoogleWorkspaceIntegration,
} from './workspace'

import {
  handleFetchMicrosoft365Authorization,
  handleFetchMicrosoft365Integrations,
  handleRemoveMicrosoft365Integration,
} from './microsoft365'

import {
  handleAddTagToAsset,
  handleRemoveTagFromAsset,
  handleFetchComplianceStats,
  handleFetchLgpdDetails,
  handleFetchLgpdFindings,
  handleFetchLgpdIntegrations,
  handleFetchLgpdItemDetails,
  handleStartLgpdMonitoring,
} from './compliance'
import {
  handleFetchQuestionnaireDetails,
  handleFetchQuestionnaires,
  handleUpdateQuestionnaireAnswer,
  handleUpdateQuestionnaire,
  handleUploadQuestionnaire,
  handleRemoveQuestionnaire,
  handleGenerateAnswer,
  handleFetchQuestionnaireTemplates,
  handleUpdateQuestionnaireTemplate,
  handleRemoveQuestionnaireTemplate,
  handleUploadQuestionnaireTemplate,
  handleSendQuestionnaire,
  handleFetchQuestionnaireInvites,
  handleRespondQuestionnaireInvite,
  handleFetchQuestionReferences,
  handleFetchSupplierQuestionnaire,
  handleAnswerReview,
  handleQuestionnaireReview,
} from './questionnaires'
import {
  handleFetchDocuments,
  handleRemoveDocument,
  handleUploadDocument,
} from './documents'

import {
  handleAddSupplier,
  handleFetchSupplierDetails,
  handleFetchSupplierInvites,
  handleFetchSuppliers,
  handleFetchSupplierSentQuestionnaires,
  handleRemoveSentQuestionnaire,
  handleRemoveSupplier,
  handleResendSupplierInvite,
  handleRespondSupplierInvite,
  handleUpdateSupplier,
  handleVerifySupplierInvite,
  handleFetchQuestionnaireEvents,
} from './suppliers'

import {
  handleFetchProduct,
  handleFetchProducts,
  handleRequestQuote,
} from './marketplace'

function* watchAll() {
  yield all([
    takeEvery('ACCEPT_TERMS', handleAcceptTerms),
    takeEvery('CANCEL_INVITE', handleCancelInvite),
    takeEvery('CHANGE_CURRENT_COMPANY', handleChangeCompany),
    takeEvery('COMPLETE_GUIDE', handleCompleteGuide),
    takeEvery('DELETE_MEMBER', handleDeleteMember),
    takeEvery('DELETE_URL', handleDeleteUrl),
    takeEvery('DISABLE_2FA', handleDisable2fa),
    takeEvery('DISCONNECT_GOOGLE', handleDisconnectGoogle),
    takeEvery('DISCONNECT_MICROSOFT', handleDisconnectMicrosoft),
    takeEvery('ENABLE_2FA', handleEnable2fa),
    takeEvery('FETCH_ACTIVITY', handleFetchActivity),
    takeEvery('FETCH_ALL_FINDINGS', handleFetchAllFindings),
    takeEvery('FETCH_ALL_FINDINGS_COUNT', handleFetchAllFindingsCount),
    takeEvery('FETCH_BULK_FINDINGS', handleFetchBulkFindings),
    takeEvery('UPDATE_FINDINGS_BULK', handleUpdateFindingsBulk),
    takeEvery('FETCH_FINDINGS_BULK_RESULT', handleFetchFindingsBulkResult),
    takeEvery('FETCH_BREACHED_ACCOUNTS', handleFetchBreachedAccounts),
    takeEvery('FETCH_BREACHES', handleFetchBreaches),
    takeEvery('FETCH_PEOPLE_ACCOUNT', handleFetchPeopleAccount),
    takeEvery(
      'FETCH_PEOPLE_ACCOUNT_FINDINGS',
      handleFetchPeopleAccountFindings
    ),
    takeEvery('FETCH_PEOPLE_FINDING', handleFetchPeopleFinding),
    takeEvery('UPDATE_PEOPLE_FINDING_STATUS', handleUpdatePeopleFindingStatus),
    takeEvery('UPDATE_FINDING_ASSIGNEE', handleUpdateFindingAssignee),
    takeEvery(
      'FETCH_PEOPLE_FINDING_ACTIVITY',
      handleFetchPeopleFindingActivity
    ),
    takeEvery('COMPANY_RENEWAL', handleCompanyRenewal),
    takeEvery('FETCH_COMPANY', handleFetchCompany),
    takeEvery('FETCH_COMPANY_MEMBERS', handleFetchCompanyMembers),
    takeEvery('FETCH_COMPANY_SCORES', handleFetchCompanyScores),
    takeEvery('FETCH_COMPANY_STATS', handleFetchCompanyStats),
    takeEvery('FETCH_COMPANIES_PAGINATION', handleFetchCompaniesPagination),
    takeEvery('FETCH_CSRF', handleFetchCsrf),
    takeEvery('FETCH_EVENTS', handleFetchEvents),
    takeEvery('FETCH_EVIDENCE', handleFetchEvidence),
    takeEvery('FETCH_FEED', handleFetchFeed),
    takeEvery('FETCH_HOST', handleFetchHost),
    takeEvery('FETCH_HOSTS', handleFetchHosts),
    takeEvery('FETCH_HOST_FINDINGS', handleFetchHostFindings),
    takeEvery('FETCH_HOSTS_BY_AS_ORGS', handleFetchHostsByAsOrgs),
    takeEvery('FETCH_HOSTS_BY_COUNTRY', handleFetchHostsByCountry),
    takeEvery('FETCH_INVITES', handleFetchInvites),
    takeEvery('FETCH_INVITE_INFO', handleInviteInfo),
    takeEvery('FETCH_ISSUES', handleFetchIssues),
    takeEvery('FETCH_NETWORK_FINDING', handleFetchNetworkFinding),
    takeEvery(
      'FETCH_FINDINGS_BY_NETWORK_SERVICE',
      handleFetchFindingsByNetworkService
    ),
    takeEvery(
      'FETCH_NETWORK_IMPORTANT_FINDINGS',
      handleFetchNetworkImportantFindings
    ),
    takeEvery(
      'FETCH_NETWORK_FINDING_ACTIVITY',
      handleFetchNetworkFindingActivity
    ),
    takeEvery('FETCH_NETWORK_STATS', handleFetchNetworkStats),
    takeEvery('FETCH_REPORTS', handleFetchReports),
    takeEvery('FETCH_SERVICE', handleFetchService),
    takeEvery('FETCH_SERVICE_DETAILS', handleFetchServiceDetails),
    takeEvery(
      'FETCH_SERVICE_DETAILS_FINDINGS',
      handleFetchServiceDetailsFindings
    ),
    takeEvery('FETCH_SERVICES', handleFetchServices),
    takeEvery(
      'FETCH_HOSTS_BY_NETWORK_SERVICE',
      handleFetchHostsByNetworkService
    ),
    takeEvery('FETCH_TOTAL_VS_CLOSED_ISSUES', handleFetchTotalVsClosedIssues),
    takeEvery('FETCH_USER', handleFetchUser),
    takeEvery('FORCE_CHECK', handleForceCheck),
    takeEvery('GENERATE_2FA', handleGenerate2fa),
    takeEvery('GENERATE_REPORT', handleGenerateReport),
    takeEvery('GENERIC_ERROR', handleFetchCsrf),
    takeEvery('INVITE_MEMBER', handleInviteMember),
    takeEvery('LOGOUT', handleLogout),
    takeEvery('REGISTER', handleRegister),
    takeEvery('ACCEPT_INVITE', handleAcceptInvite),
    takeEvery('REMOVE_WEBHOOK', handleRemoveWebhook),
    takeEvery('SAVE_PROFILE', handleSaveProfile),
    takeEvery('SEND_LEAD', handleSendLead),
    takeEvery('SUBMIT_SURVEY', handleSubmitSurvey),
    takeEvery('TOGGLE_COMPANY_MONITORING', handleToggleCompanyMonitoring),
    takeEvery('UPDATE_COMPANY', handleUpdateCompany),
    takeEvery('UPDATE_SORT', updateSort),
    takeEvery('UPDATE_ISSUE_STATUS', handleUpdateIssueStatus),
    takeEvery('UPDATE_NETWORK_FINDING', handleUpdateNetworkFinding),
    takeEvery('UPDATE_NOTIFICATION_SETTINGS', handleUpdateNotificationSettings),
    takeEvery('UPDATE_PASSWORD', handleUpdatePassword),
    takeEvery('UPDATE_USER_ROLE', handleUpdateUserRole),
    takeEvery('VERIFY_SESSION', handleVerifySession),
    takeEvery('FETCH_SCORE_OVER_TIME', handleFetchScoreOverTime),
    takeEvery('FETCH_BREACHES_OVER_TIME', handleFetchBreachesOverTime),
    takeEvery('FETCH_AUDIT_LOGS', handleFetchAuditLogs),
    takeEvery('FETCH_NEXT_STEPS', handleFetchNextSteps),
    takeEvery('GENERATE_API_KEY', handleGenerateApiKey),
    takeEvery('REMOVE_API_KEY', handleRemoveApiKey),
    takeEvery('FETCH_API_KEYS', handleFetchApiKeys),

    takeEvery('AUTHENTICATE', handleAuthenticate),
    takeEvery('CHECK_2FA', handleCheck2fa),
    takeEvery('FORGOT_PASSWORD', handleForgotPassword),
    takeEvery('RESET_PASSWORD', handleResetPassword),
    takeEvery('SIGN_UP', handleSignUp),

    // Webhooks
    takeEvery('ADD_WEBHOOK', handleAddWebhook),
    takeEvery('FETCH_WEBHOOKS', handleFetchWebhooks),
    takeEvery('FETCH_WEBHOOK_LOGS', handleFetchWebhookLogs),
    takeEvery('REMOVE_WEBHOOK', handleRemoveWebhook),
    takeEvery('TEST_WEBHOOK', handleTestWebhook),
    takeEvery('TOGGLE_WEBHOOK', handleToggleWebhook),
    takeEvery('UPDATE_WEBHOOK', handleUpdateWebhook),

    // DNS
    takeEvery('ADD_DOMAIN', handleAddDomain),
    takeEvery('DEL_DOMAIN', handleRemoveDomain),
    takeEvery('FETCH_DNS_FEED', handleFetchDnsFeed),
    takeEvery('FETCH_DNS_FINDING', handleFetchDnsFinding),
    takeEvery('FETCH_DNS_FINDING_ACTIVITY', handleFetchDnsFindingActivity),
    takeEvery('FETCH_DNS_STATS', handleFetchDnsStats),
    takeEvery('FETCH_DOMAIN', handleFetchDomain),
    takeEvery('FETCH_DOMAINS', handleFetchDomains),
    takeEvery('FETCH_DOMAINS_BREAKDOWN', handleFetchDomainsBreakdown),
    takeEvery('FETCH_DOMAINS_HOSTNAMES', handleFetchDomainsHostnames),
    takeEvery('FETCH_DOMAINS_BY_REGISTRAR', handleFetchDomainsByRegistrar),
    takeEvery('FETCH_DOMAIN_FINDINGS', handleFetchDomainFindings),
    takeEvery('FETCH_DOMAIN_RECORDS', handleFetchDomainRecords),
    takeEvery('FETCH_DNS_RECORDS_LOGS', handleFetchDnsRecordsLogs),
    takeEvery('FETCH_DOMAIN_SUBDOMAINS', handleFetchDomainSubdomains),
    takeEvery('FETCH_NAME_SERVER_DETAILS', handleFetchNameServerDetails),
    takeEvery('FETCH_NAME_SERVERS', handleFetchNameServers),
    takeEvery('UPDATE_DNS_FINDING', handleUpdateDnsFinding),

    // Web
    takeEvery('FETCH_FINGERPRINT_DETAILS', handleFetchFingerprintDetails),
    takeEvery('FETCH_WEB_APP', handleFetchWebApp),
    takeEvery('FETCH_WEB_APPS', handleFetchWebApps),
    takeEvery('FETCH_WEB_APP_FINDINGS', handleFetchWebAppFindings),
    takeEvery('FETCH_WEB_APPS_BY_PROTOCOL', handleFetchWebAppsByProtocol),
    takeEvery('FETCH_WEB_APPS_FINGERPRINTS', handleFetchWebAppsFingerprints),
    takeEvery('FETCH_WEB_FINDING', handleFetchWebFinding),
    takeEvery('FETCH_WEB_FINDING_ACTIVITY', handleFetchWebFindingActivity),
    takeEvery('FETCH_WEB_STATS', handleFetchWebStats),
    takeEvery('FETCH_WEB_IMPORTANT_FINDINGS', handleFetchWebImportantFindings),
    takeEvery('MERGE_WEB_APPS', handleMergeWebApps),
    takeEvery('UNMERGE_WEB_APP', handleUnmergeWebApp),
    takeEvery('UPDATE_WEB_APP', handleUpdateWebApp),
    takeEvery('UPDATE_WEB_FINDING', handleUpdateWebFinding),
    takeEvery('ADD_WEB_APP', handleAddWebApp),

    // CLOUD
    takeEvery('FETCH_CLOUD_FEED', handleFetchCloudFeed),
    takeEvery('FETCH_CLOUD_INTEGRATIONS', handleFetchCloudIntegrations),
    takeEvery(
      'FETCH_ACTIVE_CLOUD_INTEGRATIONS',
      handleFetchActiveCloudIntegrations
    ),
    takeEvery('FETCH_CLOUD_STATS', handleFetchCloudStats),
    takeEvery('FETCH_HAS_CLOUD_INTEGRATION', handleFetchHasCloudIntegration),

    // AWS
    takeEvery('FETCH_AWS_FINDING', handleFetchAwsFinding),
    takeEvery('FETCH_AWS_FINDINGS', handleFetchAwsFindings),
    takeEvery('FETCH_AWS_FINDINGS_BY_ASSET', handleFetchAwsFindingsByAsset),
    takeEvery('FETCH_AWS_INTEGRATIONS', handleFetchAwsIntegrations),
    takeEvery('FETCH_AWS_MOST_CRITICAL', handleFetchAwsMostCritical),
    takeEvery('FETCH_AWS_STATS', handleFetchAwsStats),
    takeEvery('FETCH_AWS_IAM_POLICIES', handleFetchAwsIamPolicies),
    takeEvery('FETCH_AWS_USERS', handleFetchAwsUsers),
    takeEvery('FETCH_EBS_VOLUMES', handleFetchEbsVolumes),
    takeEvery('FETCH_KMS_KEYS', handleFetchKmsKeys),
    takeEvery('FETCH_EC2_AMIS', handleFetchEc2Amis),
    takeEvery('FETCH_EC2_INSTANCES', handleFetchEc2Instances),
    takeEvery('FETCH_EC2_SECURITY_GROUPS', handleFetchEc2SecurityGroups),
    takeEvery('FETCH_EC2_VPCS', handleFetchEc2Vpcs),
    takeEvery('FETCH_EFS', handleFetchEfs),
    takeEvery('FETCH_CLOUDTRAIL_TRAILS', handleFetchCloudtrailTrails),
    takeEvery('FETCH_RDS_INSTANCES', handleFetchRdsInstances),
    takeEvery('FETCH_RDS_SNAPSHOTS', handleFetchRdsSnapshots),
    takeEvery('FETCH_S3_BUCKETS', handleFetchS3Buckets),
    takeEvery('FETCH_ROUTE_53_DOMAINS', handleFetchRoute53Domains),
    takeEvery('FETCH_ROUTE_53_HOSTED_ZONES', handleFetchRoute53HostedZones),
    takeEvery('INTEGRATE_AWS', handleIntegrateAws),
    takeEvery('STOP_AWS_INTEGRATION', handleStopAwsIntegration),
    takeEvery('RETRY_AWS_INTEGRATION', handleRetryAwsIntegration),
    takeEvery('FETCH_AWS_FINDING_ACTIVITY', handleFetchAwsFindingActivity),
    takeEvery('UPDATE_AWS_FINDING', handleUpdateAwsFinding),

    // GCP
    takeEvery('FETCH_GCP_SQL_INSTANCES', handleFetchGcpSqlInstances),
    takeEvery('FETCH_GCP_BUCKETS', handleFetchGcpBuckets),
    takeEvery('FETCH_GCP_FINDING', handleFetchGcpFinding),
    takeEvery('FETCH_GCP_FINDINGS', handleFetchGcpFindings),
    takeEvery('FETCH_GCP_FINDINGS_BY_ASSET', handleFetchGcpFindingsByAsset),
    takeEvery('FETCH_GCP_FINDING_ACTIVITY', handleFetchGcpFindingActivity),
    takeEvery('FETCH_GCP_INSTANCES', handleFetchGcpInstances),
    takeEvery('FETCH_GCP_INTEGRATION', handleFetchGcpIntegration),
    takeEvery('FETCH_GCP_INTEGRATIONS', handleFetchGcpIntegrations),
    takeEvery('FETCH_GCP_KMS', handleFetchGcpKms),
    takeEvery('FETCH_GCP_MOST_CRITICAL', handleFetchGcpMostCritical),
    takeEvery('FETCH_GCP_PRINCIPALS', handleFetchGcpPrincipals),
    takeEvery('FETCH_GCP_SERVICE_ACCOUNTS', handleFetchGcpFetchServiceAccounts),
    takeEvery(
      'FETCH_GCP_SERVICE_ACCOUNT_KEYS',
      handleFetchGcpServiceAccountKeys
    ),
    takeEvery('FETCH_GCP_STATS', handleFetchGcpStats),
    takeEvery('INTEGRATE_GCP', handleIntegrateGcp),
    takeEvery('RETRY_GCP_INTEGRATION', handleRetryGcpIntegration),
    takeEvery('STOP_GCP_INTEGRATION', handleStopGcpIntegration),
    takeEvery('UPDATE_GCP_FINDING', handleUpdateGcpFinding),
    takeEvery(
      'UPDATE_GCP_INTEGRATION_VERSION',
      handleUpdateGcpIntegrationVersion
    ),

    // Azure
    takeEvery('FETCH_AZURE_BLOB_CONTAINERS', handleFetchAzureBlobContainers),
    takeEvery('FETCH_AZURE_DISKS', handleFetchAzureDisks),
    takeEvery('FETCH_AZURE_MYSQL_SERVERS', handleFetchAzureMysqlServers),
    takeEvery(
      'FETCH_AZURE_POSTGRESQL_SERVERS',
      handleFetchAzurePostgresqlServers
    ),
    takeEvery('FETCH_AZURE_FINDING', handleFetchAzureFinding),
    takeEvery('FETCH_AZURE_FINDINGS', handleFetchAzureFindings),
    takeEvery(
      'FETCH_AZURE_FINDINGS_BY_RESOURCE',
      handleFetchAzureFindingsByResource
    ),
    takeEvery(
      'FETCH_AZURE_FINDINGS_BY_SERVICE',
      handleFetchAzureFindingsByService
    ),
    takeEvery('FETCH_AZURE_FINDING_ACTIVITY', handleFetchAzureFindingActivity),
    takeEvery('FETCH_AZURE_INTEGRATIONS', handleFetchAzureIntegrations),
    takeEvery('FETCH_AZURE_MOST_CRITICAL', handleFetchAzureMostCritical),
    takeEvery('FETCH_AZURE_RESOURCE_GROUPS', handleFetchAzureResourceGroups),
    takeEvery('FETCH_AZURE_SQL_DATABASES', handleFetchAzureSqlDatabases),
    takeEvery('FETCH_AZURE_SQL_SERVERS', handleFetchAzureSqlServers),
    takeEvery('FETCH_AZURE_STATS', handleFetchAzureStats),
    takeEvery('FETCH_AZURE_STORAGE_ACCOUNTS', handleFetchAzureStorageAccounts),
    takeEvery('FETCH_AZURE_SUBSCRIPTIONS', handleFetchAzureSubscriptions),
    takeEvery('FETCH_AZURE_VM_INSTANCES', handleFetchAzureVmInstances),
    takeEvery('INTEGRATE_AZURE', handleIntegrateAzure),
    takeEvery('UPDATE_AZURE_FINDING_STATUS', handleUpdateAzureFindingStatus),
    takeEvery('STOP_AZURE_INTEGRATION', handleStopAzureIntegration),

    // Compliance
    takeEvery('FETCH_COMPLIANCE_STATS', handleFetchComplianceStats),

    // LGPD
    takeEvery('ADD_TAG_TO_ASSET', handleAddTagToAsset),
    takeEvery('REMOVE_TAG_FROM_ASSET', handleRemoveTagFromAsset),
    takeEvery('FETCH_LGPD_INTEGRATIONS', handleFetchLgpdIntegrations),
    takeEvery('FETCH_LGPD_DETAILS', handleFetchLgpdDetails),
    takeEvery('FETCH_LGPD_ITEM_DETAILS', handleFetchLgpdItemDetails),
    takeEvery('FETCH_LGPD_FINDINGS', handleFetchLgpdFindings),
    takeEvery('START_LGPD_MONITORING', handleStartLgpdMonitoring),

    // Google Workspace
    takeEvery('REMOVE_WORKSPACE_INTEGRATION', handleRemoveWorkspaceIntegration),
    takeEvery(
      'FETCH_GOOGLE_WORKSPACE_INTEGRATIONS',
      handleFetchGoogleWorkspaceIntegrations
    ),
    takeEvery('FETCH_COMPANY_EMAILS', handleFetchCompanyEmails),
    takeEvery(
      'FETCH_GOOGLE_WORKSPACE_FINDING',
      handleFetchGoogleWorkspaceFinding
    ),
    takeEvery(
      'FETCH_GOOGLE_WORKSPACE_FINDING_ACTIVITY',
      handleFetchGoogleWorkspaceFindingActivity
    ),
    takeEvery(
      'UPDATE_GOOGLE_WORKSPACE_FINDING_STATUS',
      handleUpdateGoogleWorkspaceFindingStatus
    ),
    takeEvery(
      'FETCH_GOOGLE_WORKSPACE_AUTHORIZATION',
      handleFetchGoogleWorkspaceAuthorization
    ),
    takeEvery(
      'UPDATE_GOOGLE_WORKSPACE_INTEGRATION',
      handleUpdateGoogleWorkspaceIntegration
    ),

    //Microsoft 365
    takeEvery(
      'FETCH_MICROSOFT_365_INTEGRATIONS',
      handleFetchMicrosoft365Integrations
    ),
    takeEvery(
      'REMOVE_MICROSOFT_365_INTEGRATION',
      handleRemoveMicrosoft365Integration
    ),
    takeEvery(
      'FETCH_MICROSOFT_365_AUTHORIZATION',
      handleFetchMicrosoft365Authorization
    ),

    // Suppliers
    takeEvery('FETCH_SUPPLIERS', handleFetchSuppliers),
    takeEvery('FETCH_SUPPLIER_DETAILS', handleFetchSupplierDetails),
    takeEvery('RESEND_SUPPLIER_INVITE', handleResendSupplierInvite),
    takeEvery('ADD_SUPPLIER', handleAddSupplier),
    takeEvery('UPDATE_SUPPLIER', handleUpdateSupplier),
    takeEvery('REMOVE_SUPPLIER', handleRemoveSupplier),
    takeEvery('FETCH_SUPPLIER_INVITES', handleFetchSupplierInvites),
    takeEvery('RESPOND_SUPPLIER_INVITE', handleRespondSupplierInvite),
    takeEvery('VERIFY_SUPPLIER_INVITE', handleVerifySupplierInvite),
    takeEvery(
      'FETCH_SUPPLIER_SENT_QUESTIONNAIRES',
      handleFetchSupplierSentQuestionnaires
    ),
    takeEvery('REMOVE_SENT_QUESTIONNAIRE', handleRemoveSentQuestionnaire),

    // Questionnaires
    takeEvery('FETCH_QUESTIONNAIRES', handleFetchQuestionnaires),
    takeEvery('FETCH_QUESTIONNAIRE_DETAILS', handleFetchQuestionnaireDetails),
    takeEvery('UPDATE_QUESTIONNAIRE_ANSWER', handleUpdateQuestionnaireAnswer),
    takeEvery('UPLOAD_QUESTIONNAIRE', handleUploadQuestionnaire),
    takeEvery('UPDATE_QUESTIONNAIRE', handleUpdateQuestionnaire),
    takeEvery('REMOVE_QUESTIONNAIRE', handleRemoveQuestionnaire),
    takeEvery('GENERATE_ANSWER', handleGenerateAnswer),
    takeEvery('FETCH_QUESTION_REFERENCES', handleFetchQuestionReferences),
    takeEvery('SEND_QUESTIONNAIRE', handleSendQuestionnaire),
    takeEvery(
      'REMOVE_QUESTIONNAIRE_TEMPLATE',
      handleRemoveQuestionnaireTemplate
    ),
    takeEvery('FETCH_QUESTIONNAIRE_INVITES', handleFetchQuestionnaireInvites),
    takeEvery('RESPOND_QUESTIONNAIRE_INVITE', handleRespondQuestionnaireInvite),
    takeEvery('FETCH_SUPPLIER_QUESTIONNAIRE', handleFetchSupplierQuestionnaire),
    takeEvery('ANSWER_REVIEW', handleAnswerReview),
    takeEvery('QUESTIONNAIRE_REVIEW', handleQuestionnaireReview),
    takeEvery('FETCH_QUESTIONNAIRE_EVENTS', handleFetchQuestionnaireEvents),

    // Questionnaires - Template
    takeEvery(
      'FETCH_QUESTIONNAIRE_TEMPLATES',
      handleFetchQuestionnaireTemplates
    ),
    takeEvery(
      'UPLOAD_QUESTIONNAIRE_TEMPLATE',
      handleUploadQuestionnaireTemplate
    ),
    takeEvery(
      'UPDATE_QUESTIONNAIRE_TEMPLATE',
      handleUpdateQuestionnaireTemplate
    ),

    // DOCUMENTS
    takeEvery('FETCH_DOCUMENTS', handleFetchDocuments),
    takeEvery('UPLOAD_DOCUMENT', handleUploadDocument),
    takeEvery('REMOVE_DOCUMENT', handleRemoveDocument),

    // MARKETPLACE
    takeEvery('FETCH_PRODUCT', handleFetchProduct),
    takeEvery('FETCH_PRODUCTS', handleFetchProducts),
    takeEvery('REQUEST_QUOTE', handleRequestQuote),
  ])
}

fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    return [url, config]
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error)
  },

  response: function (response) {
    // Modify the reponse object

    if (response.status === 401 && !isPublicView()) {
      const {href, pathname} = window.location

      if (href.startsWith(config.CLIENT_URL) && !pathname.endsWith('/404')) {
        localStorage.setItem('redirect', pathname)
      }
    }

    if (response.status === 404 && !isPublicView()) {
      return window.location.replace('/404')
    }

    if (response.status === 403) {
      // CLONE TO ALLOW ACCESS TO BODY PROPERTY
      const clonedResponse = response.clone()

      return clonedResponse.json().then(body => {
        const isCsrfRequest = new URL(response.request.url).pathname === '/csrf'

        const shouldShowErrorMsg =
          !isCsrfRequest &&
          !isPublicView() &&
          body.code !== 'SUBSCRIPTION_LIMIT'

        if (shouldShowErrorMsg) return put(genericError({code: 'FORBIDDEN'}))

        return response
      })
    }

    return response
  },

  responseError: function (error) {
    if (['/login', '/mfa'].includes(window.location.pathname))
      return put(genericError())
    // Handle an fetch error
    return Promise.reject(error)
  },
})

export default watchAll
