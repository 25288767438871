import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Trans} from 'react-i18next'
import Alert from './Alert'

const PaymentFailedAlert = () => {
  const userState = useSelector(state => state.user)

  const [showAlert, setShowAlert] = useState(false)

  const paymentAlertHasBeenDisplayed = JSON.parse(
    localStorage.getItem('paymentAlertHasBeenDisplayed')
  )

  const onCloseAlert = () => {
    localStorage.setItem('paymentAlertHasBeenDisplayed', true)
    setShowAlert(false)
  }

  useState(() => {
    if (!paymentAlertHasBeenDisplayed && userState.company.paymentFailed) {
      setShowAlert(true)
    }
  }, [])

  return (
    <Alert
      iconContent={
        <i className="icofont-info-circle text-red-800 text-lg mr-2" />
      }
      content={
        <Trans
          i18nKey="paymentFailedAlert.content"
          components={{
            a: (
              <a
                onClick={() => window.open(userState.customerPortal, '_blank')}
                className="underline hover:cursor-pointer"
              />
            ),
            i: <i className="icofont-external-link" />,
          }}
        />
      }
      style="bg-red-200 text-red-900"
      showAlert={showAlert}
      onCloseAlert={onCloseAlert}
    />
  )
}

export default PaymentFailedAlert
