import React from 'react'
import {Slide, ToastContainer} from 'react-toastify'
import {alertStyle, orange700, red700, sky900, teal700} from 'constant'

export default function ToastMessage() {
  const contextClass = {
    success: alertStyle.success,
    error: alertStyle.error,
    warning: alertStyle.warning,
    info: alertStyle.info,
  }

  return (
    <ToastContainer
      transition={Slide}
      toastClassName={context =>
        `${context?.type}-toast ${
          contextClass[context?.type || 'info']
        } relative flex p-4 pt-3 mb-2 text-sm rounded-md justify-between overflow-hidden shadow-lg`
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: 'fit-content',
        maxWidth: '60%',
        '--toastify-icon-color-success': teal700,
        '--toastify-icon-color-error': red700,
        '--toastify-icon-color-warning': orange700,
        '--toastify-icon-color-info': sky900,
        '--toastify-color-progress-success': teal700,
        '--toastify-color-progress-error': red700,
        '--toastify-color-progress-warning': orange700,
        '--toastify-color-progress-info': sky900,
      }}
      closeButton={({closeToast}) => (
        <div onClick={closeToast}>
          <i className="icofont-close text-sm hover:cursor-pointer ml-6" />
        </div>
      )}
    />
  )
}
