import i18next from 'i18next'
import {getPropByLocale} from 'utils'
import {generateCSV} from 'utils/csv'

function escapeDoubleQuotes(str) {
  return str.replace(/"/g, '""')
}

export const generateQuestionnaireCSV = (userState, questionnaireDetails) => {
  const companyName = encodeURIComponent(userState.company.name.split(' ')[0])

  const questionnaireName = questionnaireDetails.name.replace(/ /g, '-')

  const csvData = questionnaireDetails.questions.map(item => {
    const question = escapeDoubleQuotes(getPropByLocale(item.content.question))

    const answer = escapeDoubleQuotes(
      item.answer && item.answer.values && item.answer.values[0]
        ? item.answer.values[0]
        : i18next.t('questionnaires.csv.notAnswered')
    )

    const considerations = escapeDoubleQuotes(
      item.answer && item.answer.comments
        ? item.answer.comments
        : i18next.t('questionnaires.csv.noConsiderations')
    )

    return {
      question: `"${question}"`,
      answer: `"${answer}"`,
      considerations: `"${considerations}"`,
    }
  })

  generateCSV({
    columns: ['question', 'answer', 'considerations'],
    columnNames: [
      i18next.t('questionnaires.csv.question'),
      i18next.t('questionnaires.csv.answer'),
      i18next.t('questionnaires.csv.considerations'),
    ],
    filename: `${companyName}-${new Date().getTime()}-${questionnaireName}.csv`,
    data: csvData,
  })
}

export const getTextAreaRowCount = text => {
  if (!text || typeof text !== 'string') {
    return '2'
  }

  if (text.length > 20 || text.match(/\n/g)) {
    return '5'
  }

  return '2'
}

export const getQuestionnaireReviewRules = status => {
  const rules = {
    showHistoryBtn: false,
    canDownload: false,
    showEvaluationElements: false,
    canEvaluate: false,
  }

  switch (status) {
    case 'accepted':
      rules.showHistoryBtn = true
      break

    case 'submitted':
      rules.showHistoryBtn = true
      rules.canDownload = true
      rules.showEvaluationElements = true
      rules.canEvaluate = true
      break

    case 'approved':
    case 'changesRequested':
      rules.showHistoryBtn = true
      rules.canDownload = true
      rules.showEvaluationElements = true
      break
  }

  return {...rules}
}

export const getQuestionnaireSupplierRules = status => {
  const rules = {
    canEdit: true,
    showSubmitBtn: true,
  }

  switch (status) {
    case 'submitted':
    case 'approved':
      rules.canEdit = false
      rules.showSubmitBtn = false
      break
  }

  return {...rules}
}
