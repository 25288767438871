import React, {useCallback, useEffect, useState} from 'react'
import i18next from 'i18next'
import moment from 'moment'
import {Trans} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import PlanUtils from 'utils/plan'
import images from 'res'
import {fetchQuestionnaireDetails, toggleRestrictionModal} from 'actions'
import {
  generateQuestionnaireCSV,
  getQuestionnaireSupplierRules,
} from './questionnairesUtils'
import {getUserLocale, getPropByLocale} from 'utils'
import {sky500, baseQuestionnaireId} from 'constant'

import BackButton from 'components/partials/buttons/BackButton'
import QuestionBox from './QuestionBox'
import Spinner from 'components/partials/Spinner'
import {Page} from 'components/partials/Page'
import TrashIcon from 'res/icons/trashIcon'
import QuestionnaireEditModal from './modals/questionnaire/QuestionnaireEditModal'
import QuestionnaireRemoveModal from './modals/questionnaire/QuestionnaireRemoveModal'
import EditIcon from 'res/icons/editIcon'
import QuestionnaireSubmitModal from './modals/questionnaire/QuestionnaireSubmitModal'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import QuestionnaireStatusBadge from './QuestionnaireStatusBadge'

export default function QuestionnaireDetails() {
  const userState = useSelector(state => state.user)
  const questionnaireDetailsState = useSelector(
    state => state.questionnaireDetails
  )
  const questionnaireBaseDetailsState = useSelector(
    state => state.questionnaireBaseDetails
  )

  const dispatch = useDispatch()
  const params = useParams()

  const planUtils = PlanUtils()

  const [openQuestionId, setOpenQuestionId] = useState()
  const [questionnaire, setQuestionnaire] = useState(null)
  const [changesTracking, setChangesTracking] = useState({
    isAnswerModified: false,
    isConsiderationsModified: false,
    isEvidencesModified: false,
  })

  const [submitQuestionnaireModal, setSubmitQuestionnaireModal] = useState({
    selectedQuestionnaire: null,
    showModal: false,
  })

  const [editQuestionnaireModal, setEditQuestionnaireModal] = useState({
    selectedQuestionnaire: null,
    showModal: false,
  })

  const [removeQuestionnaireModal, setRemoveQuestionnaireModal] = useState({
    selectedQuestionnaire: null,
    showModal: false,
  })

  const getDisplayName = user => {
    if (!user) {
      return ''
    }

    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }

    return user.firstName
  }

  const handleGenerateCSV = questionnaireDetails => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    generateQuestionnaireCSV(userState, questionnaireDetails)
  }

  const isBaseQuestionnaire = id => {
    return id === baseQuestionnaireId
  }

  const questionHasUnsavedChanges = () => {
    return (
      changesTracking.isAnswerModified ||
      changesTracking.isConsiderationsModified ||
      changesTracking.isEvidencesModified
    )
  }

  const preventDefaultCallback = useCallback(e => e.preventDefault(), [])

  useEffect(() => {
    dispatch(fetchQuestionnaireDetails({questionnaireId: params.id}))
    moment.locale(getUserLocale(userState))
  }, [])

  useEffect(() => {
    const questionnaire = isBaseQuestionnaire(params.id)
      ? questionnaireBaseDetailsState
      : questionnaireDetailsState

    if (questionnaire?.id === params.id) setQuestionnaire(questionnaire)
  }, [questionnaireBaseDetailsState, questionnaireDetailsState])

  useEffect(() => {
    questionHasUnsavedChanges()
      ? window.addEventListener('beforeunload', preventDefaultCallback)
      : window.removeEventListener('beforeunload', preventDefaultCallback)

    // remove event after leaving the page
    return () => {
      window.removeEventListener('beforeunload', preventDefaultCallback)
    }
  }, [changesTracking])

  const questionnaireRules = getQuestionnaireSupplierRules(
    questionnaire?.status
  )

  if (!questionnaire) return <Spinner />

  return (
    <>
      <Page
        pageTitle={i18next.t('pageTitles.questionnaires')}
        helpPath="questionnaire">
        <BackButton fallbackLink="/questionnaires" />

        <div className="rounded border border-sideral-100 bg-white mt-4">
          <div className="group">
            <div className="flex w-full border-b p-4 items-center justify-between">
              <div className="w-full break-words">
                <div className="mb-1">
                  <h1 className="w-full text-ink text-lg">
                    {isBaseQuestionnaire(questionnaire.id)
                      ? i18next.t('questionnaires.base.title')
                      : questionnaire.name}
                  </h1>

                  <p className="text-sideral-700 text-sm w-full">
                    {isBaseQuestionnaire(questionnaire.id)
                      ? i18next.t('questionnaires.base.description')
                      : getPropByLocale(questionnaire.description)}
                  </p>
                </div>

                {!isBaseQuestionnaire(questionnaire.id) && (
                  <QuestionnaireStatusBadge
                    status={questionnaire.status}
                    changedAt={questionnaire.statusChangedAt}
                    isAnUploadedQuestionnaire={
                      questionnaire.type === 'uploaded'
                    }
                    isFromSupplier
                  />
                )}
              </div>
              <div
                id="action-buttons"
                className="flex items-center justify-end w-full">
                <div className="flex text-xs text-right mr-4 pr-4 border-r">
                  <div>
                    <div>
                      {questionnaire.type === 'received' ? (
                        <>
                          <Trans
                            i18nKey="questionnaires.sentInfo"
                            values={{
                              companyName: questionnaire.companyName,
                              receivedAt: moment(
                                questionnaire.receivedAt
                              ).fromNow(),
                            }}
                          />
                          <div>
                            {i18next.t('questionnaires.acceptanceInfo', {
                              createdAt: moment(
                                questionnaire.createdAt
                              ).fromNow(),
                            })}
                          </div>
                        </>
                      ) : (
                        <Trans
                          i18nKey="questionnaires.creationInfo"
                          values={{
                            createdAt: moment(
                              questionnaire.createdAt
                            ).fromNow(),
                            createdBy: getDisplayName(questionnaire.createdBy),
                          }}
                        />
                      )}
                    </div>
                    {questionnaire.updatedBy ? (
                      <div>
                        <Trans
                          i18nKey="questionnaires.updateInfo"
                          values={{
                            updatedAt: moment(
                              questionnaire.latestAnswer
                            ).fromNow(),
                            updatedBy: getDisplayName(questionnaire.updatedBy),
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                {!isBaseQuestionnaire(questionnaire.id) &&
                  questionnaireRules.showSubmitBtn && (
                    <PrimaryButton
                      text={i18next.t('questionnaires.submit.submitBtn')}
                      margin="mr-4"
                      miniature
                      onClick={() =>
                        setSubmitQuestionnaireModal({
                          selectedQuestionnaire: {
                            id: questionnaire.id,
                            name: questionnaire.name,
                            companyName: questionnaire.companyName,
                            type: questionnaire.type,
                          },
                          showModal: true,
                        })
                      }
                      disabled={questionnaire.progress !== '100%'}
                    />
                  )}

                {!isBaseQuestionnaire(questionnaire.id) && (
                  <button
                    onClick={() =>
                      setEditQuestionnaireModal({
                        selectedQuestionnaire: {
                          id: questionnaire.id,
                          name: questionnaire.name,
                          description: questionnaire.description,
                          submittedAt:
                            questionnaire.status === 'submitted'
                              ? questionnaire.statusChangedAt
                              : null,
                        },
                        showModal: true,
                      })
                    }
                    title={i18next.t('misc.edit')}
                    disabled={questionnaire.type === 'received'}>
                    <EditIcon height="22" width="22" color={sky500} />
                  </button>
                )}

                <button
                  className="mx-2"
                  onClick={() => handleGenerateCSV(questionnaire)}
                  title={i18next.t('buttons.downloadCSV')}>
                  <images.DownloadIcon height="24" width="24" color={sky500} />
                </button>

                {!isBaseQuestionnaire(questionnaire.id) && (
                  <button
                    onClick={() =>
                      setRemoveQuestionnaireModal({
                        selectedQuestionnaire: {
                          id: questionnaire.id,
                          name: questionnaire.name,
                        },
                        showModal: true,
                      })
                    }
                    title={i18next.t('misc.remove')}
                    disabled={questionnaire.type === 'received'}>
                    <TrashIcon height="22" width="22" color={sky500} />
                  </button>
                )}
              </div>
            </div>

            <div className="group">
              <div className="overflow-hidden bg-gray-500 h-1 transition-all ease-in duration-150 group-hover:h-6 text-center grid">
                <div
                  className="bg-sky-500 transition-all ease-in duration-150 group-hover:h-6 col-start-1 row-start-1"
                  style={{
                    width: questionnaire.progress,
                  }}></div>
                <span className="text-center text-xs uppercase col-start-1 row-start-1 p-1 text-white">
                  {i18next.t('questionnaires.progress')}
                  <span className="ml-1 font-medium">
                    {questionnaire.progress}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="border-t">
            {questionnaire?.questions?.map((question, questionIndex) => (
              <QuestionBox
                key={`${question.questionId} ${questionIndex}`}
                questionnaireId={params.id}
                questionnaireRules={questionnaireRules}
                questionGroup={question}
                questionIndex={questionIndex}
                openQuestionId={openQuestionId}
                setOpenQuestionId={setOpenQuestionId}
                setChangesTracking={setChangesTracking}
                questionHasUnsavedChanges={questionHasUnsavedChanges}
              />
            ))}
          </div>
        </div>
      </Page>

      {submitQuestionnaireModal.showModal && (
        <QuestionnaireSubmitModal
          submitQuestionnaireModal={submitQuestionnaireModal}
          setSubmitQuestionnaireModal={setSubmitQuestionnaireModal}
        />
      )}

      {editQuestionnaireModal.showModal && (
        <QuestionnaireEditModal
          editQuestionnaireModal={editQuestionnaireModal}
          setEditQuestionnaireModal={setEditQuestionnaireModal}
        />
      )}

      {removeQuestionnaireModal.showModal && (
        <QuestionnaireRemoveModal
          removeQuestionnaireModal={removeQuestionnaireModal}
          setRemoveQuestionnaireModal={setRemoveQuestionnaireModal}
        />
      )}
    </>
  )
}
