import i18next from 'i18next'
import Chart from 'react-apexcharts'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {
  fetchComplianceStats,
  fetchLgpdDetails,
  fetchLgpdFindings,
  fetchLgpdIntegrations,
  fetchLgpdItemDetails,
  redirect404,
  toggleRestrictionModal,
} from 'actions'

import {generateCSV} from 'utils/csv'
import images from 'res'
import {sideral300, sky500} from 'constant'
import {getFeatureSubscription, getPropByLocale} from 'utils'
import PlanUtils from 'utils/plan'

import Spinner from 'components/partials/Spinner'
import TableWrapper from 'components/partials/tables/TableWrapper'
import ActiveIntegrationsTable from './ActiveIntegrationsTable'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import FindingsTable from './FindingsTable'
import StatsBox from 'components/partials/StatsBox'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import ModalControl from './ModalControl'
import {Page} from 'components/partials/Page'

export default function Dashboard() {
  const userState = useSelector(state => state.user)
  const lgpdIntegrationsState = useSelector(state => state.lgpdIntegrations)
  const lgpdDetailsState = useSelector(state => state.lgpdDetails)
  const lgpdItemDetailsState = useSelector(state => state.lgpdItemDetails)
  const lgpdFindingsState = useSelector(state => state.lgpdFindings)
  const complianceStatsState = useSelector(state => state.complianceStats)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [chartColor, setChartColor] = useState({
    chart: '#a0aec0',
    background: 'bg-gray-500',
  })
  const [filteredFindings, setFilteredFindings] = useState([])
  const [modalVisibility, setModalVisibility] = useState('hidden')

  const planUtils = PlanUtils()

  const chartData = {
    options: {
      chart: {
        type: 'radialBar',
      },
      colors: [chartColor.chart],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '75%',
          },
          dataLabels: {
            name: {
              show: true,
              fontSize: '18px',
            },
            value: {
              show: true,
              fontSize: '20px',
            },
          },
        },
      },
      labels: [i18next.t('compliance.lgpd.stats.adhesion')],
    },
  }

  const updateChartColor = percentage => {
    if (percentage <= 20)
      return setChartColor({chart: '#c53030', border: 'border-red-700'})
    if (percentage > 20 && percentage <= 40)
      return setChartColor({chart: '#f56565', border: 'border-red-500'})
    if (percentage > 40 && percentage <= 60)
      return setChartColor({chart: '#ed8936', border: 'border-orange-500'})
    if (percentage > 60 && percentage <= 80)
      return setChartColor({chart: '#ecc94b', border: 'border-yellow-500'})
    if (percentage > 80 && percentage <= 99)
      return setChartColor({chart: '#48bb78', border: 'border-green-500'})
    if (percentage === 100)
      return setChartColor({chart: '#38b2ac', border: 'border-teal-500'})

    return setChartColor({chart: '#a0aec0', border: 'border-gray-500'})
  }

  const integrationsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('compliance.lgpd.stats.integrations'),
            accessor: 'name',
          },
          {
            Header: i18next.t('compliance.lgpd.stats.assets'),
            accessor: 'assetsCount',
            width: 70,
          },
          {
            Header: i18next.t('compliance.lgpd.stats.findings'),
            accessor: 'findingsCount',
            width: 70,
          },
          {
            Header: i18next.t('compliance.lgpd.stats.adhesion'),
            accessor: 'percentage',
            width: 70,
          },
          {
            accessor: 'accountAlias',
          },
        ],
      },
    ],
    []
  )

  const findingsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.title').toUpperCase(),
            accessor: 'titleNew',
            disableFilters: true,
          },
          {
            Header: i18next.t('tables.asset').toUpperCase(),
            accessor: 'asset',
            disableFilters: true,
          },
          {
            Header: i18next.t('compliance.lgpd.tables.article').toUpperCase(),
            accessor: 'lgpdItems',
          },
        ],
      },
    ],
    []
  )

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const companyName = encodeURIComponent(
      userState?.company.name.split(' ')[0]
    )

    const csvData = filteredFindings.map(findings => {
      const lgpdItems = Array.isArray(findings.lgpdItems)
        ? findings.lgpdItems
            .map(articles => {
              articles.title = articles.title.replace(/,/g, ' -')
              return articles.title
            })
            .join('; ')
        : ''

      return {
        title: getPropByLocale(findings.titleNew) || '',
        asset: findings.asset || '',
        lgpdItems,
      }
    })

    generateCSV({
      columns: ['title', 'asset', 'lgpdItems'],
      columnNames: [
        i18next.t('tables.title'),
        i18next.t('tables.asset'),
        i18next.t('compliance.lgpd.tables.article'),
      ],
      filename: `${companyName}-${new Date().getTime()}-findings.csv`,
      data: csvData,
    })
  }

  const toggleModal = lgpdItemId => {
    dispatch(fetchLgpdItemDetails(lgpdItemId))

    modalVisibility === 'hidden'
      ? setModalVisibility('block')
      : setModalVisibility('hidden')
  }

  const hasActiveLgpdIntegration = integrations => {
    if (!integrations) return false

    return integrations.some(integration => integration.monitoring)
  }

  useEffect(() => {
    if (!getFeatureSubscription(userState, 'cloudIntegrations').hasAccess)
      return dispatch(redirect404())

    dispatch(fetchLgpdIntegrations())
    dispatch(fetchLgpdDetails())
    dispatch(fetchComplianceStats())

    if (!planUtils.hasPlanRestriction()) dispatch(fetchLgpdFindings())
  }, [])

  useEffect(() => {
    if (!lgpdIntegrationsState) return

    if (!hasActiveLgpdIntegration(lgpdIntegrationsState))
      navigate('/compliance')
  }, [lgpdIntegrationsState])

  useEffect(() => {
    if (!complianceStatsState || !complianceStatsState.lgpd) return

    updateChartColor(complianceStatsState.lgpd.percentage)
  }, [complianceStatsState])

  if (!complianceStatsState || !lgpdDetailsState || !lgpdIntegrationsState)
    return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.compliance')}>
      <ModalControl
        lgpdItem={lgpdItemDetailsState}
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
      />

      <div className="grid md:grid-cols-2 xl:grid-cols-7 gap-4 w-full">
        <StatsBox
          classContainer="md:col-span-2 xl:col-span-3"
          slots={2}
          title={`/${i18next.t('compliance.lgpd.stats.integrations')}`}
          label={`/${i18next.t('compliance.lgpd.stats.providers')}`}
          data={complianceStatsState.lgpd.cloudIntegrationsCount}
          secondaryLabel={`/${i18next.t('misc.assets')}`}
          secondaryData={complianceStatsState.lgpd.assetsCount}
        />

        <StatsBox
          classContainer="xl:col-span-2"
          slots={1}
          title={`/${i18next.t('misc.findings')}`}
          data={complianceStatsState.lgpd.findingsCount}
          size="xs"
        />

        <div
          className={`xl:col-span-2 ${chartColor.border} border-2 rounded-lg w-full mb-10 md:mb-0`}>
          <Chart
            options={chartData.options}
            series={[complianceStatsState.lgpd.percentage]}
            type="radialBar"
            height={210}
          />
        </div>
      </div>

      <TableWrapper
        title={i18next.t('compliance.lgpd.integrationsUnderMonitoring')}
        button={
          <SecondaryButton
            text={i18next.t('compliance.lgpd.buttons.manage')}
            plusIcon={true}
            size="sm"
            margin="ml-auto"
            onClick={() => {
              navigate('/compliance/lgpd/integration')
            }}
          />
        }
        loaded={lgpdDetailsState}
        margin="my-8"
        table={
          <ActiveIntegrationsTable
            data={lgpdDetailsState}
            columns={integrationsColumns}
            pageNumber={0}
            pageSize={10}
            pageIndex={0}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin={'m-2 mt-4'}
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin={'m-2 mt-4'}
              />
            }
          />
        }
      />

      <TableWrapper
        id="findingsTable"
        button={
          <button
            className="ml-auto"
            onClick={handleGenerateCSV}
            title={i18next.t('buttons.downloadCSV')}>
            <images.DownloadIcon height="24" width="24" color={sky500} />
          </button>
        }
        title={i18next.t('misc.findings')}
        loaded={lgpdFindingsState}
        table={
          <FindingsTable
            columns={findingsColumns}
            data={lgpdFindingsState}
            pageNumber={0}
            pageSize={10}
            filteredFindings={filteredFindings}
            setFilteredFindings={setFilteredFindings}
            toggleModal={toggleModal}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin={'mt-6 mb-2'}
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('titles.goodNews')}
                body={i18next.t('management.noFindings')}
                margin={'mt-6 mb-2'}
              />
            }
          />
        }
        isPremiumFeature
      />
    </Page>
  )
}
