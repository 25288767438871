import React, {useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import SecondaryButton from './buttons/SecondaryButton'
import images from 'res'
import {sky900} from 'constant'

export default function CopyToClipboard({title, content, onClick, classes}) {
  const hiddenMessage = 'opacity-0'
  const visibleMessage = 'opacity-1 -translate-y-1/3'

  const [successMessage, setSuccessMessage] = useState(hiddenMessage)

  const showClipboardSuccess = () => {
    if (successMessage === hiddenMessage) {
      setSuccessMessage(visibleMessage)

      setTimeout(() => {
        setSuccessMessage(hiddenMessage)
      }, 1500)
    }
  }

  return (
    <div className={classes}>
      <div className="flex justify-between bg-astral-100 px-4 py-2 border rounded-t-md">
        <div className="font-medium">{title}</div>
        <div className="relative">
          <div
            className={`absolute -top-[1.5rem] bg-teal-700 text-white text-xs rounded px-2 py-1 transition-all duration-150 ease-out ${successMessage}`}>
            {i18next.t('misc.copiedToClipboard')}
          </div>

          <SecondaryButton
            text={i18next.t('buttons.copy')}
            icon={<images.copyIcon width="18" height="18" color={sky900} />}
            onClick={() => {
              onClick()
              showClipboardSuccess()
            }}
            size="sm"
            classContainer="inline-flex"
          />
        </div>
      </div>
      <div
        className={
          'dm-mono text-xs border-x border-b rounded-b-md bg-astral-50 overflow-auto p-4 max-h-64'
        }>
        {content}
      </div>
    </div>
  )
}

CopyToClipboard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  classes: PropTypes.string,
}
