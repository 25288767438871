import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {getPropByLocale, getUserLocale} from 'utils'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {updateQuestionnaireTemplate} from 'actions'
import PrimaryTextArea from 'components/partials/inputs/PrimaryTextArea'

export default function QuestionnaireTemplateEditModal({
  editModal,
  setEditModal,
}) {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const initialFormState = {
    id: editModal.selected.id,
    name: editModal.selected.name,
    description: editModal.selected.description,
  }

  const userLocale = getUserLocale(userState)

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = e => {
    const {name, value} = e.target

    if (name === 'description') {
      setForm({...form, [name]: {[userLocale]: value}})
    } else setForm({...form, [name]: value})
  }

  const handleSubmit = () => {
    dispatch(
      updateQuestionnaireTemplate({
        questionnaireId: form.id,
        name: form.name,
        description: form.description[userLocale],

        _csrf: csrfState,
      })
    )

    setEditModal({
      selected: null,
      showModal: false,
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <DefaultModal
        id="editModal"
        title={i18next.t('questionnaires.templates.edit.modal.title')}
        body={
          <div className="text-sm text-gray-700 leading-6 mb-8">
            <PrimaryInput
              text={i18next.t('questionnaires.templates.edit.modal.name')}
              name="name"
              type="name"
              value={form.name}
              onChange={handleInputChange}
              required
            />

            <PrimaryTextArea
              id="description"
              name="description"
              text={i18next.t(
                'questionnaires.templates.edit.modal.description'
              )}
              value={getPropByLocale(form.description)}
              onChange={handleInputChange}
            />
          </div>
        }
        primaryBtnText={i18next.t('buttons.save')}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={() =>
          setEditModal({
            selected: null,
            showModal: false,
          })
        }
        primaryButtonType="submit"
      />
    </form>
  )
}

QuestionnaireTemplateEditModal.propTypes = {
  questionnaireId: PropTypes.string,
  editModal: PropTypes.object,
  setEditModal: PropTypes.func,
}
