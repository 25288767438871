import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import hljs from 'highlight.js'
import {useSelector, useDispatch} from 'react-redux'
import {i18next} from 'translate/i18n'
import {useParams} from 'react-router-dom'

import {fetchCompanyMembers} from 'actions'
import {getDisplayName, getPropByLocale} from 'utils'

import BackButton from 'components/partials/buttons/BackButton'
import ContentBox from 'components/partials/ContentBox'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import Spinner from 'components/partials/Spinner'
import ModalControl from './compliance/lgpd/ModalControl'
import FindingAssignment from './partials/FindingAssignment'
import {Page} from './partials/Page'
import AcceptRiskModal from './management/AcceptRiskModal'

export default function GenericFinding(props) {
  const dispatch = useDispatch()
  const csrfState = useSelector(state => state.csrf)
  const lgpdItemDetailsState = useSelector(state => state.lgpdItemDetails)

  const params = useParams()

  const [currentAssignee, setCurrentAssignee] = useState({})
  const [acceptRiskModalVisibility, setAcceptRiskModalVisibility] =
    useState(false)

  const RISK_ACCEPTED_STATUS = 'riskAccepted'

  const displayName = activity => {
    if (activity.userStatus === 'removed') {
      return `${getDisplayName(
        activity.firstName,
        activity.lastName
      )} (${i18next.t('misc.removed').toLowerCase()})`
    }

    return getDisplayName(activity.firstName, activity.lastName)
  }

  const fetchUpdateFinding = (value, justification = null) => {
    dispatch(
      props.updateFinding({
        findingId: props.finding.id,
        updateStatusMessage: getUpdateStatusMessage(
          props.finding.status,
          value
        ),
        status: value,
        justification,
        _csrf: csrfState,
      })
    )
  }

  const handleSelectChange = event => {
    const {value} = event

    if (value === props.finding.status) return

    if (value === RISK_ACCEPTED_STATUS) setAcceptRiskModalVisibility(true)
    else fetchUpdateFinding(value)
  }

  const getStatusLabel = value => {
    return i18next.t(`status.${value}`)
  }

  const getUpdateStatusMessage = (prev, current) => {
    return `${i18next.t('finding.statusUpdatedFrom')} ${i18next.t(
      `status.${prev}`
    )} ${i18next.t('misc.to')} ${i18next.t(`status.${current}`)}`
  }

  useEffect(() => {
    if (params.id) {
      dispatch(props.fetchFinding(params.id))
      dispatch(props.fetchFindingActivity(params.id))
    }
  }, [dispatch, params.id])

  useEffect(() => {
    if (!props.finding) return

    if (
      props.finding.data &&
      JSON.stringify(props.finding.data).length <= 10000
    ) {
      hljs.highlightAll()
    }
  }, [props.finding])

  useEffect(() => {
    dispatch(fetchCompanyMembers())
  }, [])

  const Activity = () => {
    return (
      <div className="py-4 px-2 mb-2">
        {props.findingActivity &&
          props.findingActivity.map(activity => {
            if (activity.type === 'assign') {
              return (
                <div
                  className="flex border-b last:border-none py-3"
                  key={activity.createdAt}>
                  <div className="w-1/2">
                    <span className="mr-1">{displayName(activity)}</span>
                    {activity.assignedToName?.trim() ? (
                      <>
                        {i18next.t('finding.assignedTo')}
                        <span className="font-medium mx-1">
                          {activity.assignedToName}
                        </span>
                      </>
                    ) : (
                      <>{i18next.t('finding.unassign')}</>
                    )}
                  </div>
                  <div className="w-1/2 text-right">
                    <span className="text-xs">
                      <Moment date={new Date(activity.createdAt)} fromNow />
                    </span>
                  </div>
                </div>
              )
            }

            if (activity.type === 'status') {
              return (
                <div
                  className="border-b last:border-none py-3"
                  key={activity.createdAt}>
                  <div className="flex">
                    <div className="w-1/2">
                      {displayName(activity)}
                      {i18next.t('finding.movedFrom')}
                      <span className="font-medium mx-1">
                        {i18next.t(
                          `status.${
                            activity.previousState ||
                            activity.previousStatus ||
                            activity.previous
                          }`
                        )}
                      </span>
                      {i18next.t('misc.to')}
                      <span className="font-medium mx-1">
                        {i18next.t(
                          `status.${
                            activity.newState ||
                            activity.newStatus ||
                            activity.new
                          }`
                        )}
                      </span>
                    </div>
                    <div className="w-1/2 text-right">
                      <span className="text-xs">
                        <Moment date={new Date(activity.createdAt)} fromNow />
                      </span>
                    </div>
                  </div>

                  {activity.justification && (
                    <div className="mt-2">
                      <span className="font-medium mr-1">
                        {i18next.t('misc.justification')}:
                      </span>
                      <span className="font-light">
                        {activity.justification}
                      </span>
                    </div>
                  )}
                </div>
              )
            }
          })}
      </div>
    )
  }

  return (
    <Page pageTitle={props.title} helpPath={props.helpPath}>
      {!props.finding ? <Spinner /> : <></>}
      {props.finding ? (
        <>
          {props.finding.hasComplianceFeature &&
          props.modalVisibility &&
          props.setModalVisibility ? (
            <ModalControl
              lgpdItem={lgpdItemDetailsState}
              modalVisibility={props.modalVisibility}
              setModalVisibility={props.setModalVisibility}
            />
          ) : (
            <></>
          )}

          {acceptRiskModalVisibility && (
            <AcceptRiskModal
              updateFinding={payload => {
                fetchUpdateFinding(RISK_ACCEPTED_STATUS, payload.justification)
              }}
              modalVisibility={acceptRiskModalVisibility}
              setModalVisibility={setAcceptRiskModalVisibility}
            />
          )}

          <div className="rounded-lg border border-sideral-100 mb-6">
            <div
              className={
                props.findingDetails
                  ? 'border-b border-sideral-100 flex'
                  : 'border-sideral-100 flex'
              }>
              {props.findingIcon()}
              <div className="w-11/12 p-4 md:flex">
                <div className="w-2/3">
                  <BackButton
                    fallbackLink={props.fallbackLink || '/management'}
                  />
                  <div className="capitalize-first max-w-md font-medium text-lg pt-2">
                    {props.finding && props.finding.template
                      ? getPropByLocale(props.finding.template.titleNew)
                      : getPropByLocale(props.finding.titleNew)}
                  </div>
                </div>
                <div className="w-1/3 md:mt-0 mt-8">
                  <div className="flex float-right">
                    {props.findingType && props.findingType !== 'info' ? (
                      <FindingAssignment
                        currentAssignee={currentAssignee}
                        finding={props.finding}
                        findingType={props.findingType}
                        setCurrentAssignee={setCurrentAssignee}
                        fetchFinding={props.fetchFinding}
                        fetchFindingActivity={props.fetchFindingActivity}
                        params={params}
                      />
                    ) : (
                      <></>
                    )}
                    <div>
                      <PrimarySelect
                        className="text-sm"
                        name="status"
                        width="16rem"
                        onChange={handleSelectChange}
                        options={props.allowedStatuses}
                        padding={1}
                        text={i18next.t('labels.status')}
                        value={{
                          value: props.finding.status,
                          label: getStatusLabel(props.finding.status),
                        }}
                        disabled={props.finding.status === 'closed'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.findingDetails(props.finding)}
          </div>

          <div className="template-markdown">
            {props.findingBody(props.finding)}
          </div>

          {props.finding.data || props.finding.rawData ? (
            <div className="template-markdown mb-6">
              <ContentBox
                header={i18next.t('titles.rawData')}
                content={<RawData finding={props.finding} />}
                padding="0"
              />
            </div>
          ) : (
            <></>
          )}

          {props.findingActivity && props.findingActivity.length ? (
            <div className="template-markdown mb-6">
              <ContentBox
                header={i18next.t('titles.activity')}
                content={<Activity />}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Page>
  )
}

const RawData = ({finding}) => {
  const preClass = `p-4 whitespace-pre-wrap max-h-[20rem] overflow-y-auto`

  try {
    if (finding.data?.url && finding.data?.html) {
      return renderWebFinding(finding.data)
    }

    if (typeof finding.data === 'object') {
      const data = finding.data.data || finding.data

      if (typeof data === 'object') {
        return renderObject(data)
      } else {
        return renderString(data)
      }
    }

    if (typeof finding.data === 'string') {
      const data = JSON.parse(finding.data)
      return renderObject(data)
    }
  } catch (err) {
    err

    return renderString(finding.data)
  }

  function renderString(data) {
    return (
      <>
        <pre className={preClass}>
          <code>{data}</code>
        </pre>
      </>
    )
  }

  function renderObject(data, jsonPadding = 4) {
    return (
      <>
        <pre className={preClass}>
          <code>{JSON.stringify(data, null, jsonPadding)}</code>
        </pre>
      </>
    )
  }

  function renderWebFinding(data) {
    // special web app finding with url and html properties
    return (
      <>
        <pre className={preClass}>
          <code>{data.url + '\n\n' + data.html}</code>
        </pre>
      </>
    )
  }
}

BackButton.propTypes = {
  fallbackLink: PropTypes.string,
}

RawData.propTypes = {
  finding: PropTypes.object,
}

GenericFinding.propTypes = {
  allowedStatuses: PropTypes.array,
  fallbackLink: PropTypes.string,
  fetchFinding: PropTypes.func,
  fetchFindingActivity: PropTypes.func,
  finding: PropTypes.object,
  findingActivity: PropTypes.array,
  findingBody: PropTypes.any,
  findingDetails: PropTypes.any,
  findingIcon: PropTypes.any,
  findingType: PropTypes.string,
  helpPath: PropTypes.string,
  history: PropTypes.object,
  modalVisibility: PropTypes.string,
  setModalVisibility: PropTypes.func,
  title: PropTypes.string,
  updateFinding: PropTypes.func,
}
