import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import i18next from 'i18next'
import config from 'config'
import {green500, mars700, sky500} from 'constant'

import {getDocumentTitle, getPropByLocale} from 'utils'
import FileIcon from 'res/icons/FileIcon'
import DownloadIcon from 'res/icons/downloadIcon'
import PrimaryTextArea from 'components/partials/inputs/PrimaryTextArea'
import SecondaryCheckbox from 'components/partials/inputs/SecondaryCheckbox'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import QuestionnaireEvaluationBadge from 'components/questionnaires/QuestionnaireEvaluationBadge'
import {useDispatch, useSelector} from 'react-redux'
import {answerReview, clearAnswerReviewFeedback} from 'actions'
import {getTextAreaRowCount} from 'components/questionnaires/questionnairesUtils'
import CheckIcon from 'res/icons/checkIcon'
import InfoIcon from 'res/icons/infoIcon'
import QuestionnaireStatusBadge from 'components/questionnaires/QuestionnaireStatusBadge'

export default function QuestionBoxReview({
  question,
  questionIndex,
  questionnaireId,
  supplierId,
  openedQuestionId,
  setOpenedQuestionId,
  questionnaireReviewRules,
  questionnaireStatus,
}) {
  const isQuestionOpen = question.id === openedQuestionId
  const hasArtifacts = question.artifacts?.length > 0

  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const answersReviewFeedbackState = useSelector(
    state => state.answersReviewFeedback
  )

  const [form, setForm] = useState({evaluation: null, comments: ''})
  const [loading, setLoading] = useState(false)

  const handleEnterKeyToggleQuestion = event => {
    if (
      event.key === 'Enter' &&
      !['button', 'submit'].includes(event.target.type)
    ) {
      handleToggleQuestion()
    }
  }

  const handleToggleQuestion = () => {
    isQuestionOpen
      ? setOpenedQuestionId(null)
      : setOpenedQuestionId(question.id)
  }

  const handleInputChange = e => {
    const {type, value} = e.target

    if (type === 'checkbox') setForm({...form, evaluation: value})
    else setForm({...form, comments: value})
  }

  const onSave = () => {
    setLoading(true)

    dispatch(
      answerReview({
        questionnaireId,
        supplierId,
        answerId: question.answerId,
        questionId: question.id,
        evaluation: form.evaluation,
        comments: form.comments,
        _csrf: csrfState,
      })
    )
  }

  useEffect(() => {
    setForm({
      evaluation: question.review?.evaluation,
      comments: question.review?.comments || '',
    })
  }, [question])

  useEffect(() => {
    if (answersReviewFeedbackState?.[question.id] === 'success') {
      setOpenedQuestionId(null)
      setLoading(false)

      setTimeout(() => {
        dispatch(
          clearAnswerReviewFeedback({
            questionId: question.id,
          })
        )
      }, 2000)
    }
  }, [answersReviewFeedbackState])

  const hasAnswerFeedback = answersReviewFeedbackState?.[question.id]

  return (
    <div className="group/question border-b border-sideral-100 last:border-b-0">
      <div className="flex justify-between items-stretch">
        <div
          id="left-side"
          className={`w-full ${
            isQuestionOpen
              ? 'font-normal'
              : 'font-light group-hover/question:text-black'
          }`}>
          <div
            id="question-text"
            className="cursor-pointer p-6"
            onClick={handleToggleQuestion}
            onKeyDown={handleEnterKeyToggleQuestion}
            tabIndex="0">
            <span className="font-medium">{questionIndex + 1}. </span>
            <span>{getPropByLocale(question.content.question)}</span>
          </div>

          {isQuestionOpen && (
            <div className="ml-6">
              {question.editedAfterReview && (
                <QuestionnaireStatusBadge status="edited" hideTooltip />
              )}

              <div className="border-l border-sideral-100 pl-6 mt-3 mb-10">
                <div className="mb-6">
                  <div className="font-medium text-sm mb-1">
                    {i18next.t('suppliers.sentQuestionnaireDetail.answer')}
                  </div>
                  <div className="whitespace-pre-wrap">
                    {question.answer?.values?.[0] || '-'}
                  </div>
                </div>

                <div className="mb-6">
                  <div className="font-medium text-sm mb-1">
                    {i18next.t('suppliers.sentQuestionnaireDetail.comment')}
                  </div>
                  <div>{question.answer?.comments || '-'}</div>
                </div>

                {hasArtifacts && (
                  <>
                    <div className="font-medium text-sm mb-2">
                      {i18next.t('questionnaires.evidences.evidencesQty', {
                        count: question.artifacts.length,
                      })}
                    </div>
                    <div className="flex flex-wrap items-center">
                      {question.artifacts.map(evidence => (
                        <Link
                          key={evidence.id}
                          to={`${config.SERVER_URL}/artifacts/${evidence.id}`}
                          target="_blank"
                          className="group/evidence cursor-pointer p-1 pl-0">
                          <div className="flex items-center border rounded p-2 mr-2 group-hover/evidence:bg-gray-200">
                            <div className="group-hover/evidence:hidden">
                              <FileIcon
                                height="26"
                                width="26"
                                color={mars700}
                              />
                            </div>

                            <div className="hidden group-hover/evidence:block cursor-pointer">
                              <DownloadIcon
                                height="26"
                                width="26"
                                color={sky500}
                              />
                            </div>

                            <div className="text-sm truncate mx-2 w-full">
                              {getDocumentTitle(evidence.type)}
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {questionnaireReviewRules.showEvaluationElements &&
          (isQuestionOpen ? (
            <div
              id="right-side"
              className="basis-2/5 shrink-0 p-6 bg-gray-100 border-l border-sideral-100">
              {!questionnaireReviewRules.canEvaluate && (
                <div className="flex items-start border-b border-b-gray-400 mb-6 pb-6">
                  <div>
                    <InfoIcon width="18" height="18" />
                  </div>
                  <div className="text-xs ml-1">
                    {questionnaireStatus === 'approved'
                      ? i18next.t('questionnaires.cannotEvaluateApproved')
                      : i18next.t(
                          'questionnaires.cannotEvaluateChangesRequested'
                        )}
                  </div>
                </div>
              )}

              <div className="font-medium mb-4">
                {i18next.t('questionnaires.review.questionBox.boxTitle')}
              </div>

              <div className="mb-6">
                <div className="text-sideral-700 text-xs font-medium mb-2">
                  {i18next.t(
                    'questionnaires.review.questionBox.evaluationLabel'
                  )}
                </div>

                <div className="flex">
                  <SecondaryCheckbox
                    id="meets"
                    value="meets"
                    text={i18next.t('questionnaires.review.evaluations.meets')}
                    checked={form.evaluation === 'meets'}
                    onChange={handleInputChange}
                    margin="w-full"
                    borderRadius="rounded-l"
                    disabled={loading || !questionnaireReviewRules.canEvaluate}
                    fullWidth
                  />

                  <SecondaryCheckbox
                    id="partiallyMeets"
                    value="partiallyMeets"
                    text={i18next.t(
                      'questionnaires.review.evaluations.partiallyMeets'
                    )}
                    checked={form.evaluation === 'partiallyMeets'}
                    onChange={handleInputChange}
                    margin="w-full"
                    borderRadius="rounded-none"
                    disabled={loading || !questionnaireReviewRules.canEvaluate}
                    fullWidth
                  />

                  <SecondaryCheckbox
                    id="doesNotMeet"
                    value="doesNotMeet"
                    text={i18next.t(
                      'questionnaires.review.evaluations.doesNotMeet'
                    )}
                    checked={form.evaluation === 'doesNotMeet'}
                    onChange={handleInputChange}
                    margin="w-full"
                    borderRadius="rounded-r"
                    disabled={loading || !questionnaireReviewRules.canEvaluate}
                    fullWidth
                  />
                </div>
              </div>

              <PrimaryTextArea
                id="comments"
                name="comments"
                text={i18next.t(
                  'questionnaires.review.questionBox.commentsLabel'
                )}
                onChange={handleInputChange}
                value={form.comments}
                rows={getTextAreaRowCount(form.comments)}
                disabled={loading || !questionnaireReviewRules.canEvaluate}
              />

              <div className="flex justify-end">
                <PrimaryButton
                  text={i18next.t('buttons.save')}
                  theme="blue-outline"
                  loading={loading}
                  onClick={onSave}
                  disabled={
                    loading ||
                    form.evaluation === null ||
                    !questionnaireReviewRules.canEvaluate
                  }
                />
              </div>
            </div>
          ) : (
            question.review && (
              <div
                id="right-side"
                className="relative shrink-0 mx-6 self-center">
                <div
                  className={`absolute top-1/2 right-0 transform -translate-y-1/2 ${
                    hasAnswerFeedback ? '' : 'opacity-0'
                  }`}>
                  <CheckIcon width="20" color={green500} />
                </div>

                <div
                  className={`transition-transform duration-300 ease-out ${
                    hasAnswerFeedback ? 'opacity-0 translate-y-1/3' : ''
                  }`}>
                  <QuestionnaireEvaluationBadge evaluation={form.evaluation} />
                </div>
              </div>
            )
          ))}
      </div>
    </div>
  )
}

QuestionBoxReview.propTypes = {
  question: PropTypes.object,
  questionnaireId: PropTypes.string,
  supplierId: PropTypes.string,
  questionIndex: PropTypes.number,
  openedQuestionId: PropTypes.string,
  setOpenedQuestionId: PropTypes.func,
  questionnaireReviewRules: PropTypes.object,
  questionnaireStatus: PropTypes.string,
  userLocale: PropTypes.string,
}
