import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {sky500} from 'constant'
import PropTypes from 'prop-types'

import images from 'res'
import config from 'config'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import QuestionBoxReview from './review/QuestionBoxReview'
import {useDispatch, useSelector} from 'react-redux'
import {fetchSupplierQuestionnaire, loading} from 'actions'
import {useParams} from 'react-router-dom'
import {isLoading} from 'utils'
import Spinner from 'components/partials/Spinner'
import QuestionnaireStatusBadge from 'components/questionnaires/QuestionnaireStatusBadge'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import QuestionnaireHistoryModal from './QuestionnaireHistoryModal'
import QuestionnaireReviewModal from './review/QuestionnaireReviewModal'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {getQuestionnaireReviewRules} from 'components/questionnaires/questionnairesUtils'

export default function SupplierQuestionnaireDetail() {
  const dispatch = useDispatch()
  const params = useParams()

  const {supplierId, questionnaireId} = params

  const loadingState = useSelector(state => state.loading)
  const supplierQuestionnaireState = useSelector(
    state => state.supplierQuestionnaire
  )

  const [openedQuestionId, setOpenedQuestionId] = useState(null)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [showReviewModal, setShowReviewModal] = useState(false)

  const questionnaireReviewRules = getQuestionnaireReviewRules(
    supplierQuestionnaireState?.status
  )

  const handleDownload = () => {
    const url = new URL(
      `${config.SERVER_URL}/suppliers/${supplierId}/questionnaires/${questionnaireId}/download`
    )
    window.open(url, '_blank')
  }

  useEffect(() => {
    dispatch(loading({supplierQuestionnaire: true}))

    dispatch(
      fetchSupplierQuestionnaire({
        supplierId,
        questionnaireId,
      })
    )
  }, [])

  if (isLoading(loadingState.supplierQuestionnaire)) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.suppliers')}>
      <BackButton fallbackLink="/suppliers" />

      <div
        id="questionnaire-detail-container"
        className="bg-white border border-sideral-100 rounded mt-4">
        <div
          id="questionnaire-header"
          className="flex items-center justify-between py-4 px-6 border-b border-sideral-100">
          <div id="left-side">
            <div className="text-black text-lg">
              {supplierQuestionnaireState.name}
            </div>
            <div className="flex items-center mt-1">
              <QuestionnaireStatusBadge
                status={supplierQuestionnaireState.status}
                changedAt={supplierQuestionnaireState.statusChangedAt}
              />
              {questionnaireReviewRules.showHistoryBtn && (
                <div className="border-l border-sideral-100 pl-3 ml-3">
                  <SecondaryButton
                    text={i18next.t('questionnaires.history.viewHistoryBtn')}
                    size="sm"
                    onClick={() => setShowHistoryModal(true)}
                  />
                </div>
              )}
            </div>
          </div>

          <div id="right-side" className="flex items-center">
            {questionnaireReviewRules.showEvaluationElements && (
              <div className="border-r border-sideral-100 pr-6 mr-6">
                <PrimaryButton
                  text={i18next.t('questionnaires.review.modal.reviewBtn')}
                  iconClass="icofont-arrow-right"
                  iconDirection="right"
                  onClick={() => setShowReviewModal(true)}
                  disabled={!questionnaireReviewRules.canEvaluate}
                />
              </div>
            )}

            <button
              onClick={handleDownload}
              title={i18next.t('buttons.download')}
              disabled={!questionnaireReviewRules.canDownload}>
              <images.DownloadIcon height="28" width="28" color={sky500} />
            </button>
          </div>
        </div>

        <div id="questionnaire-body">
          {supplierQuestionnaireState.questions.map(
            (question, questionIndex) => (
              <QuestionBoxReview
                key={question.id}
                question={question}
                questionIndex={questionIndex}
                questionnaireId={questionnaireId}
                supplierId={supplierId}
                openedQuestionId={openedQuestionId}
                setOpenedQuestionId={setOpenedQuestionId}
                questionnaireReviewRules={questionnaireReviewRules}
                questionnaireStatus={supplierQuestionnaireState.status}
              />
            )
          )}
        </div>
      </div>

      {showHistoryModal && (
        <QuestionnaireHistoryModal
          questionnaireId={questionnaireId}
          supplierId={supplierId}
          setShowHistoryModal={setShowHistoryModal}
        />
      )}

      {showReviewModal && (
        <QuestionnaireReviewModal
          supplierId={supplierId}
          questionnaireId={questionnaireId}
          questions={supplierQuestionnaireState.questions}
          setShowReviewModal={setShowReviewModal}
        />
      )}
    </Page>
  )
}

SupplierQuestionnaireDetail.propTypes = {
  supplierId: PropTypes.string,
}
