import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import BackButton from 'components/partials/buttons/BackButton'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import PrimaryTextArea from 'components/partials/inputs/PrimaryTextArea'
import Spinner from 'components/partials/Spinner'
import TableWrapper from 'components/partials/tables/TableWrapper'
import Tag from 'components/partials/Tag'
import {Page} from 'components/partials/Page'
import {fetchProduct, loading, requestQuote} from 'actions'

export default function Marketplace() {
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loading({product: true}))
    dispatch(fetchProduct(params.productId))
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.marketplace')}>
      <TableWrapper
        id="partners-table"
        title={i18next.t('marketplace.hire')}
        loaded={true}
        table={<Product productId={params.productId} />}
      />
    </Page>
  )
}

function getInitialForm(questions) {
  if (!questions) {
    return {}
  }

  const form = {}
  for (const question of questions) {
    form[question.name] = ''
  }
  return form
}

function Product({productId}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const productState = useSelector(state => state.product[productId])
  const clearQuoteFormState = useSelector(state => state.clearQuoteForm)

  const initialForm = getInitialForm(productState?.questions)

  const [form, setForm] = useState({...initialForm})
  const [product, setProduct] = useState(productState)

  useEffect(() => {
    if (productState) {
      setProduct(productState)
    }
  }, [productState])

  function handleSelectChange(option, event) {
    setForm({
      ...form,
      [event.name]: option,
    })
  }

  function handleInputChange(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  function handleSubmit(event) {
    event.preventDefault()
    const payload = {productId: product.id, answers: {}}

    for (const key in form) {
      payload.answers[key] = form[key]
    }

    payload.productId = product.id
    payload._csrf = csrfState

    dispatch(requestQuote(payload))
  }

  useEffect(() => {
    setForm({...initialForm})
  }, [clearQuoteFormState, productState])

  if (!product) {
    return <Spinner />
  }

  return (
    <>
      <div className="md:flex md:py-6 md:px-4">
        <div className="relative rounded w-full md:w-6/12">
          <div className="p-4">
            <div className="flex justify-between md:pr-2">
              <div>
                <h2 className="text-xl font-medium">{product.name}</h2>
                <h3 className="text-lg font-thin text-sm mb-4">
                  {i18next.t('marketplace.by')} {product.partner.name}
                </h3>
              </div>
              <div>
                <img src={product.partner.logo} className="w-32 m-2" />
              </div>
            </div>

            <div className="w-10/12">
              <div className="mt-2 text-gray-700 font-light">
                <ReactMarkdown>{product.description}</ReactMarkdown>
              </div>

              <h4 className="font-medium mt-4">Sobre</h4>
              <div className="mt-2 text-gray-700 font-light">
                {product.partner.description}
              </div>
            </div>

            <div className="mt-4">
              <Tag content={product.category.name} />
            </div>
          </div>

          <div className="bg-gray-100 px-4 py-2">
            <div className="flex justify-between items-center">
              <div className="font-thin text-sm text-gray-700">
                {i18next.t('marketplace.startingAt')}
                <span className="font-normal"> {product.startingPrice}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="md:border-l md:px-4 md:py-2 w-full md:w-6/12">
          <div className="p-4 md:p-10">
            <form onSubmit={handleSubmit}>
              {product.questions.map((question, index) => {
                if (question.type === 'text') {
                  return (
                    <div className="py-2" key={index}>
                      <PrimaryInput
                        text={question.question}
                        required={question.required}
                        name={question.name}
                        value={form[question.name] || ''}
                        onChange={handleInputChange}
                        placeholder={question.placeholder}
                      />
                    </div>
                  )
                }

                if (question.type === 'select') {
                  return (
                    <div className="py-2" key={index}>
                      <PrimarySelect
                        text={question.question}
                        required={question.required}
                        name={question.name}
                        value={form[question.name]}
                        options={question.options}
                        onChange={handleSelectChange}
                        isSearchable={false}
                      />
                    </div>
                  )
                }

                if (question.type === 'multiSelect') {
                  return (
                    <div className="py-2" key={index}>
                      <PrimarySelect
                        text={question.question}
                        required={question.required}
                        isMulti={true}
                        name={question.name}
                        value={form[question.name]}
                        options={question.options}
                        onChange={handleSelectChange}
                      />
                    </div>
                  )
                }

                if (question.type === 'textarea') {
                  return (
                    <div className="py-2" key={index}>
                      <PrimaryTextArea
                        id={question.name}
                        name={question.name}
                        text={question.question}
                        onChange={handleInputChange}
                        value={form[question.name]}
                        placeholder={question.placeholder}
                        rows="4"
                        margin="0"
                      />
                    </div>
                  )
                }
              })}

              <label
                htmlFor="termsAcceptance"
                className="flex mb-8 pl-1 my-4 cursor-pointer">
                <input
                  checked={form.terms}
                  className="form-checkbox h-5 w-5"
                  id="termsAcceptance"
                  name="termsAcceptance"
                  onChange={handleInputChange}
                  required
                  type="checkbox"
                />
                <span className="text-gray-700 ml-2 font-light">
                  {i18next.t('marketplace.sharedInfo', {
                    partnerName: product.partner.name,
                  })}
                </span>
              </label>

              <div className="flex justify-between mt-2 mb-4">
                <div className="mt-2">
                  <BackButton fallbackLink="/marketplace" />
                </div>
                <PrimaryButton
                  text={i18next.t('marketplace.requestQuote')}
                  type="submit"
                  loading={false}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

Product.propTypes = {
  productId: PropTypes.string,
}
