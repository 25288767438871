import {
  fetchApiKeys,
  fetchApiKeysDone,
  generateApiKeyDone,
  genericError,
  loading,
  success,
} from 'actions'
import {handler} from './handler'
import config from 'config'
import i18next from 'i18next'

export function* handleGenerateApiKey(action) {
  yield handler({
    handler: _generateApiKey,
    error: genericError,
    success: [
      () =>
        success({message: i18next.t('settings.apiKeys.messages.generated')}),
      generateApiKeyDone,
      fetchApiKeys,
    ],
    action,
  })
}

const _generateApiKey = function (body) {
  return fetch(config.SERVER_URL + '/api/keys', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveApiKey(action) {
  yield handler({
    handler: _removeApiKey,
    error: genericError,
    success: [
      () => loading({removingApiKey: false}),
      () =>
        success({
          message: i18next.t('settings.apiKeys.messages.removed'),
        }),
      fetchApiKeys,
    ],
    action,
  })
}

const _removeApiKey = function (body) {
  return fetch(`${config.SERVER_URL}/api/keys/${body.keyId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchApiKeys(action) {
  yield handler({
    handler: _fetchApiKeys,
    error: genericError,
    success: fetchApiKeysDone,
    action,
  })
}

const _fetchApiKeys = function () {
  return fetch(`${config.SERVER_URL}/api/keys`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
