import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import images from 'res'
import PaperForm from './PaperForm'
import PlanUtils from 'utils/plan'
import i18next from 'i18next'
import {companyRenewal, showAutoSubscriptionModal} from 'actions'
import {Trans} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import SecondaryButton from './buttons/SecondaryButton'

const FeatureAdvantages = ({contentId, type, classes, closeModal}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const [showPaperForm, setShowPaperform] = useState(false)

  const planUtils = PlanUtils()
  const isPremiumPlan = planUtils.userHasPremiumPlan()

  const labels = planUtils.getPlanModalLabels()
  const featureLabels = planUtils.getFeatureAdvantagesLabels(contentId)

  const isLimitRestrictionType = type === 'limitRestriction'

  const handleTalkToSales = () => {
    setShowPaperform(true)

    dispatch(
      companyRenewal({
        _csrf: csrfState,
        companyId: userState.company.id,
        element: 'talkToSales',
        source: contentId,
      })
    )
  }

  const onClickPrimaryBtn = () => {
    if (isLimitRestrictionType) handleTalkToSales()
    else {
      closeModal()
      dispatch(showAutoSubscriptionModal(true))
    }
  }

  return (
    <>
      {showPaperForm ? (
        <div className={`${classes} max-w-lg w-full p-8`}>
          <PaperForm
            classes="relative"
            title={labels.paperformTitles}
            onClickBack={() => setShowPaperform(false)}
          />
        </div>
      ) : (
        <>
          <div
            id="sales-banner"
            className={`${classes} sm:flex items-center max-w-[800px] w-full p-8 pt-16`}>
            <div className="left-side basis-1/2 sm:border-r pr-8 z-10 ">
              <div className="inline-block mb-3">
                <div className="flex bg-green-600 text-white px-2 py-[2px] rounded-md">
                  <span className="mr-2">
                    <images.crownIcon width="16" height="16" />
                  </span>

                  <span className="uppercase text-xs">
                    {isPremiumPlan
                      ? i18next.t('sales.upgradeBadge')
                      : i18next.t('sales.subscribeBadge')}
                  </span>
                </div>
              </div>

              <div className="leading-7 mb-4">
                <Trans shouldUnescape i18nKey={featureLabels.details} />
              </div>

              <PrimaryButton
                text={
                  isLimitRestrictionType
                    ? i18next.t('sales.talkToSalesBtn')
                    : i18next.t('buttons.startForFree')
                }
                size="full"
                onClick={onClickPrimaryBtn}
              />

              {!isPremiumPlan && (
                <PrimaryButton
                  text={i18next.t('sales.secondaryBtn')}
                  theme="blue-outline"
                  size="full"
                  margin="mt-2 mb-2"
                  onClick={() => {
                    closeModal()
                    navigate('/why-unxpose')
                  }}
                />
              )}

              {!isLimitRestrictionType && (
                <div className="text-center">
                  <SecondaryButton
                    text={i18next.t('sales.talkToSalesBtn')}
                    size="sm"
                    onClick={handleTalkToSales}
                  />
                </div>
              )}
            </div>
            <div className="right-side basis-1/2 sm:pl-10 mt-10 sm:mt-0 z-10">
              <div className="font-medium text-sideral-90 text-lg mb-5">
                {featureLabels.title}
              </div>
              <ul className="check-icon text-gray-600 text-sm">
                {featureLabels.listItems.map(item => (
                  <li key={uuidv4()} className="mb-2 last:mb-0">
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <button
              className="absolute top-0 right-0 p-8 pt-6"
              onClick={closeModal}>
              <i className="icofont-close text-xl" />
            </button>
          </div>
        </>
      )}
    </>
  )
}

FeatureAdvantages.propTypes = {
  contentId: PropTypes.string,
  type: PropTypes.string,
  classes: PropTypes.string,
  closeModal: PropTypes.func,
}

export default FeatureAdvantages
