import PropTypes from 'prop-types'
import React from 'react'
import i18next from 'i18next'
import Select, {components} from 'react-select'

import {sideral100, sky500} from 'constant'

export default function PrimarySelect(props) {
  const reactSelectStyles = {
    placeholder: provided => ({
      ...provided,
      color: '#A0AFC0',
    }),
    control: (provided, state) => ({
      ...provided,

      padding: props.padding || 4,
      width: props.width || '100%',
      marginBottom: props.marginBottom || 12,
      boxShadow: state.isFocused ? '0' : '0',
      borderColor: state.isFocused ? sky500 : sideral100,
      '&:hover': {
        border: `1px solid ${sky500}`,
      },
    }),
  }

  return (
    <div>
      {props.text && (
        <label className="block tracking-wide text-sideral-700 text-sm font-medium mb-1">
          {props.text}
          {props.required && !props.text.endsWith('*') && (
            <span className="text-red-500 ml-1">*</span>
          )}
        </label>
      )}
      <Select
        className={props.className}
        defaultValue={props.defaultValue}
        isDisabled={props.disabled}
        isSearchable={props.isSearchable}
        name={props.name}
        onChange={props.onChange}
        options={props.options}
        placeholder={props.placeholder || i18next.t('misc.select')}
        styles={reactSelectStyles}
        value={props.value}
        menuPlacement={props.menuPlacement || 'bottom'}
        required={props.required}
        onMenuOpen={props.onMenuOpen}
        onMenuClose={props.onMenuClose}
        openMenuOnClick={props.openMenuOnClick}
        isLoading={props.isLoading}
        loadingMessage={() => i18next.t('misc.loading')}
        isMulti={props.isMulti}
        formatOptionLabel={data => (
          <div data-testid={`${data.value}-option`}>
            {props.formatOptionLabel
              ? props.formatOptionLabel(data)
              : data.label}
          </div>
        )}
        filterOption={props.filterOption}
        components={{
          NoOptionsMessage: () => <></>,
          MenuList: menuProps => {
            const hasNoOptions = menuProps.options.length === 0

            return (
              <>
                <components.MenuList {...menuProps}>
                  {menuProps.children}
                </components.MenuList>

                {(hasNoOptions || props.addNewButton) && (
                  <div className="flex justify-center gap-3 text-sideral-400 mb-3">
                    {hasNoOptions && <div>{i18next.t('misc.noOptions')}</div>}
                    {props.addNewButton}
                  </div>
                )}
              </>
            )
          },
        }}
      />
    </div>
  )
}

PrimarySelect.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  marginBottom: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  padding: PropTypes.number,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.any,
  width: PropTypes.string,
  menuPlacement: PropTypes.string,
  required: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  openMenuOnClick: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  addNewButton: PropTypes.object,
  filterOption: PropTypes.func,
}
