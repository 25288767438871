import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {useSelector} from 'react-redux'
import PlanUtils from 'utils/plan'

const PAPERFORM_EN_ID = 'q1izc9l3'
const PAPERFORM_PT_BR_ID = '8arqw7se'

const PaperForm = ({classes, title, onClickBack, useCloseIcon}) => {
  const userState = useSelector(state => state.user)

  const paperformId =
    i18next.language === 'pt-br' ? PAPERFORM_PT_BR_ID : PAPERFORM_EN_ID

  const plan = PlanUtils().userHasPremiumPlan() ? 'CUSTOMER' : 'FREE'

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://paperform.co/__embed.min.js'
    document.body.appendChild(script)
  }, [])

  return (
    <div className={classes}>
      <div id="paperform-content">
        <div
          id="paperform-header"
          className="text-sideral-90 text-center mb-2 px-10">
          <div className="text-md font-medium">{title.primary}</div>
          <div className="text-sm">{title.secondary}</div>
        </div>

        <div
          className="max-h-[34rem] overflow-y-auto"
          data-paperform-id={paperformId}
          data-prefill={`name=${userState.firstName} ${userState.lastName}&email=${userState.email}&plan=${plan}`}
          data-spinner
        />
      </div>

      <button
        className={`absolute top-0 ${useCloseIcon ? 'right-0' : 'left-0'}`}
        onClick={onClickBack}>
        {useCloseIcon ? (
          <i className="icofont-close text-xl" />
        ) : (
          <i className="icofont-simple-left text-xl" />
        )}
      </button>
    </div>
  )
}

PaperForm.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.object,
  onClickBack: PropTypes.func,
  useCloseIcon: PropTypes.bool,
}

export default PaperForm
