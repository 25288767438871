import i18next from 'i18next'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import images from 'res'
import {
  getSeverityString,
  getSeverityLabel,
  getSeverityColor,
} from 'utils/score'
import {sideral300, statusList, tableStyles} from 'constant'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import {getPropByLocale} from 'utils'

export default function WebAppFindingsTable(props) {
  const filterState = useSelector(state => state.filter)

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    setPageSize,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 10,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    setPageSize(props.pageSize)
  }, [props.pageSize])

  useEffect(() => {
    if (filterState) {
      setGlobalFilter(filterState)
    }
  }, [filterState, items, setGlobalFilter])

  return (
    <div>
      <div className="tableWrap">
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column =>
                      column.Header ===
                      i18next.t('tables.title').toUpperCase() ? (
                        <th
                          className={tableStyles.tableHeader}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      ) : (
                        <th
                          className={tableStyles.tableHeader}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      )
                    )}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={tableStyles.tableRow}
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (
                        cell.column.id === 'updatedAt' ||
                        cell.column.id === 'checkedAt'
                      ) {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={tableStyles.tableData}>
                            <Moment date={new Date(cell.value)} fromNow />
                          </td>
                        )
                      }

                      if (cell.column.id === 'status')
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {statusList[cell.value] || cell.value}
                          </td>
                        )

                      if (cell.column.id === 'titleNew')
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <div className="flex items-center ml-2">
                              <Link to={`/web/finding/${cell.row.original.id}`}>
                                <span
                                  className={`${getSeverityColor(
                                    cell.row.values.severity
                                  )} severity-indicator inline-block flex-shrink-0 mr-2`}></span>

                                <span className="truncate">
                                  {getPropByLocale(cell.row.original.titleNew)}
                                </span>
                              </Link>
                            </div>
                          </td>
                        )

                      if (
                        cell.column.id === 'severity' &&
                        cell.value &&
                        typeof cell.value === 'number'
                      )
                        return (
                          <td
                            className={tableStyles.tableData + ' capitalize'}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {getSeverityLabel(getSeverityString(cell.value))}
                          </td>
                        )

                      return (
                        <td
                          className={tableStyles.tableData}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <GenericEmptyState
            icon={<images.WebIcon width="50" color={sideral300} />}
            title={i18next.t('titles.goodNews')}
            body={i18next.t('web.noFindingsInWebApp')}
          />
        )}
      </div>
    </div>
  )
}

WebAppFindingsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  headerClasses: PropTypes.string,
  history: PropTypes.object,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  paginationClass: PropTypes.string,
  rowClasses: PropTypes.string,
}
