import PropTypes from 'prop-types'
import React from 'react'
import {v4 as uuidv4} from 'uuid'
import {useNavigate} from 'react-router-dom'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {i18next} from 'translate/i18n'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import FindingIcon from 'res/icons/findingIcon'
import DnsIcon from 'res/icons/dnsIcon'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'
import Moment from 'react-moment'
import {statusList, tableStyles} from 'constant'
import {getPropByLocale} from 'utils'

export default function InnerTable(props) {
  const navigate = useNavigate()

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, page} =
    useTable(
      {
        columns: props.columns,
        data: props.data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: props.pageSize || 5,
          sortBy: props.defaultSorted || [],
        },
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useFlexLayout,
      usePagination
    )

  function handleEmptyState() {
    switch (props.tab) {
      case 'findings':
        return (
          <GenericEmptyState
            icon={<FindingIcon width="55" color="#90A4AE" />}
            body={i18next.t('dns.noFindings')}
            buttonText={i18next.t('buttons.details')}
            linkPath={`/dns/domain/${props.rowId}`}
            bgColor={'bg-astral-50'}
            margin="m-2"
          />
        )

      case 'records':
        return (
          <GenericEmptyState
            icon={<DnsIcon width="55" color="#90A4AE" />}
            title={i18next.t('titles.noResultsFound')}
            body={i18next.t('dns.noRecords')}
            buttonText={i18next.t('buttons.details')}
            linkPath={`/dns/domain/${props.rowId}`}
            bgColor={'bg-astral-50'}
            margin="m-2"
          />
        )

      case 'subdomains':
        return (
          <GenericEmptyState
            icon={<DnsIcon width="55" color="#90A4AE" />}
            title={i18next.t('titles.noResultsFound')}
            body={i18next.t('dns.noSubdomains')}
            buttonText={i18next.t('buttons.details')}
            linkPath={`/dns/domain/${props.rowId}`}
            bgColor={'bg-astral-50'}
            margin="m-2"
          />
        )
    }
  }

  return (
    <div className="w-full">
      {!props.data.length ? (
        handleEmptyState()
      ) : (
        <div className="tableWrap relative">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column => (
                      <th
                        className={`${
                          column.Header === 'Title' ? '' : ''
                        } dm-mono text-sideral-300 text-sm`}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={uuidv4()}>
                        {column.render('Header').toUpperCase()}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`${
                      props.tab === 'findings' ? 'cursor-pointer' : ''
                    } ${tableStyles.tableRow}`}
                    onClick={() => {
                      if (props.tab !== 'findings') return
                      navigate(`/dns/finding/${row.original.id}`)
                    }}
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'titleNew') {
                        return (
                          <td
                            className={cell.column.classes || ''}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <span
                              className={`${getSeverityColor(
                                getSeverityString(cell.row.original.severity)
                              )} severity-indicator inline-flex flex-shrink-0 mr-2`}></span>
                            {cell.value
                              ? getPropByLocale(cell.row.original.titleNew)
                              : '—'}
                          </td>
                        )
                      }
                      if (cell.column.Header === i18next.t('tables.severity')) {
                        return (
                          <td
                            className={cell.column.classes || ''}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value
                              ? getSeverityLabel(getSeverityString(cell.value))
                              : '—'}
                          </td>
                        )
                      }

                      if (cell.column.Header === 'Status') {
                        return (
                          <td
                            className={
                              cell.column.classes + ' capitalize-first' || ''
                            }
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value ? statusList[cell.value] : '—'}
                          </td>
                        )
                      }

                      if (cell.column.Header === i18next.t('tables.seen')) {
                        const moment =
                          cell.row.original.lastNmapScan ||
                          cell.row.original.checkedAt ||
                          cell.row.original.updatedAt
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={cell.column.classes || ''}>
                            <Moment date={new Date(moment)} fromNow />
                          </td>
                        )
                      }
                      if (
                        cell.column.Header === i18next.t('tables.value') &&
                        typeof cell.value === 'object'
                      ) {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={cell.column.classes}>
                            {Array.isArray(cell.value)
                              ? cell.value.map((value, index) => {
                                  return (
                                    <span key={uuidv4()}>
                                      {typeof value === 'string'
                                        ? value
                                        : JSON.stringify(value)}

                                      {index < cell.value.length - 1
                                        ? ', '
                                        : ''}
                                    </span>
                                  )
                                })
                              : JSON.stringify(cell.value)}
                          </td>
                        )
                      }
                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="flex justify-center">
            <SecondaryButton
              text={i18next.t('buttons.details')}
              size="sm"
              onClick={() => {
                navigate(`/dns/domain/${props.rowId}`)
              }}
              margin="mt-4"
              direction="right"
            />
          </div>
        </div>
      )}
    </div>
  )
}

InnerTable.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowId: PropTypes.string,
  rowClasses: PropTypes.string,
  tab: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
}
