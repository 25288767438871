import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React from 'react'
import {v4 as uuidv4} from 'uuid'
import {Link} from 'react-router-dom'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'

import {findingsPaths} from 'config'
import {statusList, tableStyles} from 'constant'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'
import {getPropByLocale} from 'utils'

export default function ServiceDetailsTable(props) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
      Filter: () => '',
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    preGlobalFilteredRows,
    previousPage,
    state: {pageIndex, globalFilter},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex mb-2">
          <div className="w-8/12">
            <div className="mr-2">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                value={globalFilter}
                rows={rows}
                setFilterValue={setGlobalFilter}
                setGlobalFilter={setGlobalFilter}
                gotoPage={gotoPage}
              />
            </div>
          </div>
          <div className="ml-auto">
            <Pagination
              handlePrevious={() => {
                previousPage()
              }}
              handleNext={() => {
                nextPage()
              }}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              paginationClass={props.paginationClass}
              pageIndex={pageIndex}
              pageCount={pageCount}
              total={rows.length || 0}
            />
          </div>
        </div>
        <div className="flex mb-2">
          {headerGroups &&
            headerGroups.map((headerGroup, index) => {
              if (!index) return <span key={uuidv4()}></span>
              return (
                <span key={uuidv4()} className="w-full">
                  {headerGroup.headers.map(column => {
                    if (!column.canFilter) return <span key={uuidv4()}></span>
                    return (
                      <span key={uuidv4()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </span>
                    )
                  })}
                </span>
              )
            })}
        </div>
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column => {
                      if (column.hidden) return <th key={uuidv4()}></th>

                      return (
                        <th
                          className={tableStyles.tableHeader}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={tableStyles.tableRow}
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.hidden) return <td key={uuidv4()}></td>

                      if (cell.column.id === 'status')
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {statusList[cell.value]}
                          </td>
                        )

                      if (
                        cell.column.id === 'severity' &&
                        typeof cell.value === 'number'
                      )
                        return (
                          <td
                            className={tableStyles.tableData + ' capitalize'}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {getSeverityLabel(getSeverityString(cell.value))}
                          </td>
                        )

                      if (cell.column.id === 'title')
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <Link
                              to={`${findingsPaths.network}/${row.original.id}`}
                              className="flex items-center">
                              <span
                                className={`${getSeverityColor(
                                  cell.row.original.severity
                                )} severity-indicator flex-shrink-0 mr-2`}></span>
                              <p className="truncate">
                                {getPropByLocale(cell.row.original.titleNew)}
                              </p>
                            </Link>
                          </td>
                        )

                      if (cell.column.id === 'checked_at') {
                        const moment = cell.row.original.checkedAt
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={tableStyles.tableData}>
                            <Moment date={new Date(moment)} fromNow />
                          </td>
                        )
                      }

                      return (
                        <td
                          className={
                            cell.column.classes
                              ? cell.column.classes
                              : 'py-2 pr-2 text-center truncate break-word'
                          }
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>
            {globalFilter
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

ServiceDetailsTable.propTypes = {
  filteredFindings: PropTypes.array,
  setFilteredFindings: PropTypes.func,
  filterValue: PropTypes.array,
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  toggleModal: PropTypes.func,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
}
