import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {showAutoSubscriptionModal} from 'actions'
import i18next from 'i18next'

import images from 'res'
import {sky500} from 'constant'
import PlanUtils from 'utils/plan'
import PaperForm from 'components/partials/PaperForm'
import SectionAdvantages from 'components/partials/SectionAdvantages'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

const FindingsEmptyState = () => {
  const dispatch = useDispatch()

  const [showPaperForm, setShowPaperform] = useState(false)

  const planUtils = PlanUtils()
  const labels = planUtils.getPlanModalLabels()

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const toggleRestrictionModal = document.querySelector(
        '#managementSalesModal'
      )
      if (e.target === toggleRestrictionModal) setShowPaperform(false)
    })
  }, [])

  return (
    <>
      <div className="w-full flex-wrap text-center border border-sideral-100 font-medium rounded-md p-4 mt-8">
        <SectionAdvantages
          headerIcon={
            <images.ManagementIcon width={'50'} height={'50'} color={sky500} />
          }
          headerTitle={i18next.t('management.sales.findingsUnavailable')}
          title={i18next.t('management.sales.title')}
          advantagesItems={[
            i18next.t('management.sales.advantages.first'),
            i18next.t('management.sales.advantages.second'),
            i18next.t('management.sales.advantages.third'),
          ]}
          actionButtons={
            <PrimaryButton
              text={i18next.t('buttons.startForFree')}
              onClick={() => dispatch(showAutoSubscriptionModal(true))}
            />
          }
          rightSideVideo={
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Bs5jYScqXr8?si=_DbKHVGyLT1zc4as"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          }
        />
      </div>

      {showPaperForm && (
        <div
          id="managementSalesModal"
          className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
          <div
            className={`fixed bg-white border rounded-md bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full p-8`}>
            <PaperForm
              classes="relative"
              title={labels.paperformTitles}
              onClickBack={() => setShowPaperform(false)}
              useCloseIcon
            />
          </div>
        </div>
      )}
    </>
  )
}

export default FindingsEmptyState
