import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {DefaultModal} from 'components/partials/DefaultModal'
import {loading, removeApiKey} from 'actions'
import {Trans} from 'react-i18next'
import {isLoading} from 'utils'

export default function RemoveApiKeyModal({modal, setModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const successState = useSelector(state => state.success)

  const onCloseModal = () => {
    setModal({invite: null, showModal: false})
  }

  const onRemoveApiKey = () => {
    dispatch(loading({removingApiKey: true}))
    dispatch(removeApiKey({keyId: modal.id, _csrf: csrfState}))
  }

  useEffect(() => {
    if (successState) onCloseModal()
  }, [successState])

  return (
    <DefaultModal
      id="remove-api-key-modal"
      title={i18next.t('settings.apiKeys.removeModal.title')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="settings.apiKeys.removeModal.body"
            values={{
              keyName: modal.name,
            }}
          />
        </div>
      }
      primaryBtnText={i18next.t('buttons.remove')}
      primaryButtonTheme="red"
      onClickPrimaryBtn={onRemoveApiKey}
      primaryButtonLoading={isLoading(loadingState.removingApiKey, false)}
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickSecondaryBtn={onCloseModal}
      onCloseModal={onCloseModal}
    />
  )
}

RemoveApiKeyModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
}
