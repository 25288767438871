import PropTypes from 'prop-types'
import React, {useState} from 'react'

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import GlobalFilter from './GlobalFilter'
import Pagination from './Pagination'
import {Link} from 'react-router-dom'

const PrimaryTableV8 = ({
  columns,
  data,
  pageSize,
  columnVisibility,
  emptyStateWithFilter,
  emptyStateWithoutFilter,
  detailPath,
  getRowStyle,
  defaultSorting,
  hideSearch,
  hidePagination,
}) => {
  const [filterValue, setFilterValue] = useState('')
  const [sorting, setSorting] = useState(defaultSorting || [])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize || 10,
  })

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setFilterValue,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      pagination,
      columnVisibility,
      sorting,
      globalFilter: filterValue,
    },
  })

  return (
    <div className="p-2">
      <div className="flex flex-wrap justify-end sm:justify-between items-center pb-2">
        <div className="w-full sm:w-auto order-last sm:order-none mt-2 sm:mt-0">
          {!hideSearch && (
            <GlobalFilter
              preGlobalFilteredRows={table.getPreFilteredRowModel().rows}
              globalFilter={filterValue}
              setFilterValue={setFilterValue}
              setGlobalFilter={setFilterValue}
              gotoPage={table.setPageIndex}
              filterWidth="w-full sm:w-64"
            />
          )}
        </div>

        <div>
          {!hidePagination && (
            <Pagination
              handlePrevious={() => table.previousPage()}
              handleNext={() => table.nextPage()}
              canPreviousPage={table.getCanPreviousPage()}
              canNextPage={table.getCanNextPage()}
              pageIndex={table.getState().pagination.pageIndex}
              pageCount={table.getPageCount()}
              total={data.length || 0}
            />
          )}
        </div>
      </div>

      {table.getFilteredRowModel().rows.length ? (
        <div className="overflow-x-auto md:overflow-visible whitespace-nowrap">
          <table className="text-sm w-full">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="dm-mono text-sideral-300 text-sm uppercase p-2"
                      align={header.column.columnDef.meta?.align}>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none inline-flex'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' ↑',
                          desc: ' ↓',
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  className={`items-center text-astral-900 border-t border-sideral-50 text-center ${
                    detailPath && 'hover:bg-sideral-50'
                  } ${getRowStyle && getRowStyle(row)}`}>
                  {row.getVisibleCells().map(cell => {
                    let cellContent = (
                      <div className="p-2">
                        {typeof cell.getValue() !== 'undefined' ||
                        cell.column.columnDef.hasCustomNoValue
                          ? flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          : '-'}
                      </div>
                    )

                    if (detailPath && !cell.column.columnDef.isButton) {
                      cellContent = (
                        <Link
                          className="hover:no-underline inline-block w-full"
                          to={detailPath(row)}>
                          {cellContent}
                        </Link>
                      )
                    }

                    return (
                      <td
                        key={cell.id}
                        align={cell.column.columnDef.meta?.align}
                        style={{
                          width: cell.column.getSize(),
                          minWidth: cell.column.columnDef.header ? 'auto' : 0, // 0 for actions column
                        }}
                        className="overflow-visible">
                        {cellContent}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={!(hideSearch && hidePagination) && 'mt-6'}>
          {filterValue ? emptyStateWithFilter : emptyStateWithoutFilter}
        </div>
      )}
    </div>
  )
}

export default PrimaryTableV8

PrimaryTableV8.propTypes = {
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  columnVisibility: PropTypes.object,
  detailPath: PropTypes.func,
  getRowStyle: PropTypes.func,
  defaultSorting: PropTypes.array,
  hideSearch: PropTypes.bool,
  hidePagination: PropTypes.bool,
}
