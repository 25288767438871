import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {DefaultModal} from 'components/partials/DefaultModal'
import {generateApiKey, loading} from 'actions'
import {Trans} from 'react-i18next'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import CopyToClipboard from 'components/partials/CopyToClipboard'
import {expirationDates} from 'constant'
import {isLoading} from 'utils'

export default function GenerateApiKeyModal({modal, setModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const generatedApiKeyState = useSelector(state => state.generatedApiKey)

  const onGenerateApiKey = e => {
    e.preventDefault()

    dispatch(loading({generatingApiKey: true}))
    dispatch(
      generateApiKey({
        label: modal.name,
        expiration: modal.expiration,
        _csrf: csrfState,
      })
    )
  }

  const onCloseModal = () => {
    setModal({invite: null, showModal: false})
  }

  const handleInputChange = e => {
    const {name, value} = e.target
    setModal({...modal, [name]: value})
  }

  const handleSelectChange = e => {
    setModal({...modal, expiration: e.value})
  }

  return generatedApiKeyState ? (
    <DefaultModal
      id="result-api-key-modal"
      title={i18next.t('settings.apiKeys.resultGenerationModal.title')}
      body={
        <div className="text-gray-700">
          <div className="mb-3">
            <Trans i18nKey="settings.apiKeys.resultGenerationModal.firstParagraph" />
          </div>

          <div>
            <Trans
              i18nKey="settings.apiKeys.resultGenerationModal.secondParagraph"
              components={{
                a: (
                  <a
                    href="https://api-docs.unxpose.com"
                    target="_blank"
                    className="underline text-sky-900 font-medium"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </div>

          <CopyToClipboard
            title="Key ID + Key Value"
            content={
              <pre>
                <code>
                  {generatedApiKeyState.secretId}:
                  {generatedApiKeyState.secretKey}
                </code>
              </pre>
            }
            onClick={() =>
              navigator.clipboard.writeText(
                `${generatedApiKeyState.secretId}:${generatedApiKeyState.secretKey}`
              )
            }
            classes="mt-6 mb-8"
          />
        </div>
      }
      primaryBtnText={i18next.t(
        'settings.apiKeys.resultGenerationModal.closeBtn'
      )}
      onClickPrimaryBtn={onCloseModal}
      onCloseModal={onCloseModal}
      width="max-w-xl"
    />
  ) : (
    <form className="mb-10" onSubmit={onGenerateApiKey}>
      <DefaultModal
        id="generate-api-key-modal"
        title={i18next.t('settings.apiKeys.generationModal.title')}
        body={
          <div className="mb-10">
            <PrimaryInput
              name="name"
              text={i18next.t('settings.apiKeys.generationModal.keyName')}
              onChange={handleInputChange}
              margin="mb-4"
              required
            />

            <PrimarySelect
              name="expiration"
              text={i18next.t(
                'settings.apiKeys.generationModal.expirationDate'
              )}
              value={expirationDates.find(
                x => x.value === modal.expirationDate
              )}
              options={expirationDates}
              onChange={handleSelectChange}
              isSearchable={false}
              required
            />
          </div>
        }
        primaryBtnText={i18next.t(
          'settings.apiKeys.generationModal.generateBtn'
        )}
        primaryButtonType="submit"
        primaryButtonLoading={isLoading(loadingState.generatingApiKey, false)}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={onCloseModal}
        onCloseModal={onCloseModal}
      />
    </form>
  )
}

GenerateApiKeyModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
}
