import config from 'config'
import {
  fetchProductDone,
  fetchProductsDone,
  genericError,
  redirectFeedback,
  requestQuoteDone,
} from 'actions'
import {handler} from './handler'
import i18next from 'i18next'

export function* handleFetchProducts(action) {
  yield handler({
    handler: _fetchProducts,
    error: res =>
      genericError({
        loading: {products: false},
        message: res.message,
      }),
    success: fetchProductsDone,
    action,
  })
}

async function _fetchProducts() {
  return fetch(`${config.SERVER_URL}/marketplace/products`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchProduct(action) {
  yield handler({
    handler: _fetchProduct,
    error: res =>
      genericError({
        loading: {product: false},
        message: res.message,
      }),
    success: fetchProductDone,
    action,
  })
}

async function _fetchProduct(id) {
  return fetch(`${config.SERVER_URL}/marketplace/products/${id}`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRequestQuote(action) {
  yield handler({
    handler: _requestQuote,
    error: res =>
      genericError({
        loading: {requestQuote: false},
        message: res.message,
      }),
    success: [
      () => requestQuoteDone(),
      () =>
        redirectFeedback({
          path: '/marketplace',
          status: 'success',
          message: i18next.t('marketplace.quoteRequested'),
        }),
    ],
    action,
  })
}

async function _requestQuote(body) {
  return fetch(`${config.SERVER_URL}/marketplace/products/${body.productId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}
