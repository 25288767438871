import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {
  fetchFindingsByNetworkService,
  fetchHostsByNetworkService,
  redirect404,
  toggleRestrictionModal,
} from 'actions'
import {getPropByLocale, getUrlFromTarget, viewFindingsByCategory} from 'utils'
import {sideral300, statusList} from 'constant'

import NetworkIcon from 'res/icons/networkIcon'
import Spinner from 'components/partials/Spinner'
import FindingsTable from './tables/FindingsTable'
import HostsTable from './tables/HostsTable'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import FindingIcon from 'res/icons/findingIcon'
import BackButton from 'components/partials/buttons/BackButton'
import TableWrapper from 'components/partials/tables/TableWrapper'

import {generateCSV, truncate} from 'utils/csv'
import PlanUtils from 'utils/plan'
import images from 'res'

import {sky500} from 'constant'
import {getSeverityLabel, getSeverityString} from 'utils/score'
import {Page} from 'components/partials/Page'

export default function Service() {
  const userState = useSelector(state => state.user)
  const findingsByServiceState = useSelector(state => state.findingsByService)
  const hostsByServiceState = useSelector(state => state.hostsByService)

  const dispatch = useDispatch()
  const params = useParams()

  const [findingsByService, setFindingsByService] = useState(null)
  const [hostsByService, setHostsByService] = useState([])

  const planUtils = PlanUtils()

  const protocol = params.protocol
  const port = params.port

  function groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach(item => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  const organizeServices = services => {
    services = services.filter(x => x)

    const groupedByPort = Array.from(groupBy(services, s => s.port))

    return groupedByPort.map(portMap => {
      const [port, services] = portMap
      return `${port}/${services[0].protocol}`
    })
  }

  const formatCsvArrays = array => {
    array = JSON.stringify(array).replaceAll(',', '; ').replace('null', '')
    array = array.replaceAll('[', '"').replaceAll(']', '"')
    array = array.replaceAll(/"/g, '')
    return array
  }

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const companyName = encodeURIComponent(userState.company.name.split(' ')[0])

    const csvData = hostsByService.map(hostsByService => {
      const row = {}
      row.host = hostsByService.name
      row.dnsRecords = formatCsvArrays(hostsByService.dnsRecords)
      row.services = formatCsvArrays(organizeServices(hostsByService.services))
      return row
    })

    generateCSV({
      columns: ['host', 'dnsRecords', 'services'],
      columnNames: [
        i18next.t('network.host'),
        i18next.t('network.relatedRecords'),
        i18next.t('network.services'),
      ],
      filename: `${companyName}-${new Date().getTime()}-hosts.csv`,
      data: csvData,
    })
  }

  const handleGenerateFindingsCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const companyName = encodeURIComponent(userState.company.name.split(' ')[0])

    const csvData = findingsByService.map(finding => {
      const row = {}

      row.asset = finding.asset || getUrlFromTarget(finding)
      row.checkedAt = finding.checkedAt
      row.severity = getSeverityLabel(getSeverityString(finding.severity))
      row.status = statusList[finding.status]
      row.title = truncate(getPropByLocale(finding.title).replace(/,/g, ''))

      return row
    })

    generateCSV({
      columns: ['asset', 'title', 'severity', 'status', 'checkedAt'],
      columnNames: [
        i18next.t('tables.asset'),
        i18next.t('tables.finding'),
        i18next.t('tables.severity'),
        i18next.t('tables.status'),
        i18next.t('tables.seen'),
      ],
      filename: `${companyName}-${new Date().getTime()}-findings.csv`,
      data: csvData,
    })
  }

  const hostsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: 'HOST',
            accessor: 'name',
            width: 120,
          },
          {
            Header: i18next.t('network.relatedRecords').toUpperCase(),
            accessor: 'dnsRecords',
            disableSortBy: true,
          },
          {
            Header: i18next.t('network.servicesDetected').toUpperCase(),
            accessor: 'services',
            disableSortBy: true,
          },
        ],
      },
    ],
    []
  )

  const findingsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.title').toUpperCase(),
            accessor: 'title',
            width: 200,
            classes: 'truncate',
          },
          {
            Header: i18next.t('tables.asset').toUpperCase(),
            accessor: 'url',
          },
          {
            Header: 'STATUS',
            accessor: 'status',
          },
          {
            Header: i18next.t('tables.severity').toUpperCase(),
            accessor: 'severity',
          },
          {
            Header: i18next.t('tables.seen').toUpperCase(),
            accessor: 'checkedAt',
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    if (!findingsByServiceState) return

    const arr = findingsByServiceState

    arr.map(finding => (finding.url = getUrlFromTarget(finding)))

    setFindingsByService(arr)
  }, [findingsByServiceState])

  useEffect(() => {
    if (hostsByServiceState) {
      const hostsByService = hostsByServiceState
      setHostsByService(() => hostsByService)
    }
  }, [hostsByServiceState])

  useEffect(() => {
    if (protocol) {
      if (protocol.match(/[^\w-]/) || protocol.length > 20) {
        dispatch(redirect404())
        return
      }
      dispatch(fetchHostsByNetworkService({protocol, port}))

      if (!planUtils.hasPlanRestriction())
        dispatch(fetchFindingsByNetworkService({protocol, port}))
    }
  }, [dispatch, params.url])

  useEffect(() => {
    if (hostsByServiceState && !hostsByServiceState.length)
      dispatch(redirect404())
  }, [hostsByServiceState])

  return (
    <Page pageTitle={i18next.t('pageTitles.network')} helpPath="network">
      {!hostsByServiceState ||
      (hostsByServiceState && !hostsByServiceState.length) ? (
        <Spinner />
      ) : (
        <div className="lg:flex lg:flex-wrap w-full">
          <div className="flex w-full rounded-lg border border-sideral-100 bg-white font-medium text-sideral-700 p-4">
            <NetworkIcon width="50" color="#90A4AE" />
            <div className="flex flex-col justify-between flex-wrap pl-4">
              <BackButton fallbackLink={'/network'} />
              <p className="w-full">
                {port}/{protocol.toUpperCase()}
              </p>
            </div>
          </div>
          <div className="hidden md:block w-full rounded-lg border border-sideral-100 bg-astral-50 text-astral-900 mt-8">
            <div className="border-b border-sideral-100 p-4 flex">
              <div className="dm-mono text-sideral-700 uppercase flex-1 font-semibold">
                Hosts
              </div>
              <button
                className="ml-auto"
                onClick={handleGenerateCSV}
                title={i18next.t('buttons.downloadCSV')}>
                <images.DownloadIcon height="24" width="24" color={sky500} />
              </button>
            </div>
            <div className="rounded-lg bg-white p-4">
              <HostsTable
                columns={hostsColumns}
                data={hostsByServiceState || []}
                pageNumber={0}
              />
            </div>
          </div>
          <TableWrapper
            id="findingsTable"
            title={i18next.t('misc.findings')}
            loaded={findingsByService}
            margin="my-8"
            button={
              <button
                className="ml-auto"
                onClick={handleGenerateFindingsCSV}
                title={i18next.t('buttons.downloadCSV')}>
                <images.DownloadIcon height="24" width="24" color={sky500} />
              </button>
            }
            table={
              <FindingsTable
                columns={findingsColumns}
                data={findingsByService || []}
                pageNumber={0}
                emptyStateWithFilter={
                  <GenericEmptyState
                    icon={<FindingIcon width="55" color={sideral300} />}
                    title={i18next.t('tables.nothingFoundWithSearch')}
                    body={i18next.t('tables.searchSomethingElse')}
                    margin="m-2"
                  />
                }
                emptyStateWithoutFilter={
                  <GenericEmptyState
                    icon={<FindingIcon width="55" color={sideral300} />}
                    body={i18next.t('network.noFindingsInService')}
                    buttonText={i18next.t('buttons.viewAllFindings')}
                    linkPath={viewFindingsByCategory('network')}
                    margin="m-2"
                  />
                }
              />
            }
            isPremiumFeature
          />
        </div>
      )}
    </Page>
  )
}

Service.propTypes = {
  history: PropTypes.object,
}
